import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ProductService } from '../../../modules/configuration/products/services/product.service';
import { ProductTypeService } from '../../../modules/configuration/product-types/services/product-type.service';
import { BrandService } from '../../../modules/configuration/brands/services/brand.service';
import { BrandDivisionService } from '../../../modules/configuration/brand-divisions/services/brand-division.service';
import { GenericNameService } from '../../../modules/configuration/generic-names/services/generic-name.service';
import { VatService } from '../../../modules/configuration/vats/services/vat.service';
import { DrugClassService } from '../../../modules/configuration/drug-classes/services/drug-class.service';
import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
    selector: 'app-add-product',
    templateUrl: './add-product.component.html',
    styleUrls: ['./add-product.component.css'],
    providers: [
        ProductService,
        ProductTypeService,
        BrandService,
        BrandDivisionService,
        GenericNameService,
        VatService,
        DrugClassService
    ]
})
export class AddProductComponent implements OnInit {

    @ViewChild('addProdModalRef') addProdModalRef: NgbModalRef;
    modalRef;
    drugType = '';
    pordUnitSubmit = false;
    pordTypeSubmit = false;
    brandSubmit = false;
    divisionSubmit = false;
    genericSubmit = false;
    medFormLoading = false;
    allUnits = [];
    allProductTypes = [];
    allBrands = [];
    allDivisions = [];
    allGenricNos = [];
    allDrugs = [];
    multiSelect: any = {};
    model: any = {};
    addModel: any = {};
    showAddForm: any = {
        'Product unit': false,
        'Product type': false,
        'Brand': false,
        'Brand division': false,
        'Generic': false
    };
    multiselect: any = {
        product_unit: true,
        drug_name: true
    };
    skeletonOptions = {style: { width: '240px', height: '29px', 'border-radius': '0px', 'margin-bottom': '0px', 'margin-top': '0px'} };
    saveTimeoutId;
    saveSub;

    constructor(private productService: ProductService, private productTypeService: ProductTypeService,
        private brandService: BrandService, private brandDivisionService: BrandDivisionService,
        private genericNameService: GenericNameService, private vatService: VatService,
        private drugClassService: DrugClassService,
        private viewContainerRef: ViewContainerRef, private modalService: NgbModal,
        private globalHelper: GlobalHelper) {}

    ngOnInit() {
        this.getMasterRecords();
        const self = this;
        this.multiSelect.prodType = this.multiSelect.brand = this.multiSelect.division = this.multiSelect.generic = false;
        this.pordTypeSubmit = this.brandSubmit = this.divisionSubmit = this.genericSubmit = true;
        setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true
            };
            self.modalRef = self.modalService.open(self.addProdModalRef, option);
            self.modalRef.result.then((result) => {}, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().showProdModal = false;
                }
            });
        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

    closeModal () {
        this.getParentComponent().showProdModal = false;
        this.modalRef.close();
    }

    changeGeneric() {
        const self = this;
        this.productService.getDrugByGeneric(this.model.generic_id).subscribe((result: any) => {
            if (result.success) {
                if (result.drug) {
                    self.drugType = 'single';
                    self.model.drug_class = result.drug.drug_name;
                    self.model.drug_class_id = result.drug.drug_class_id;
                } else {
                    self.drugType = 'multi';
                    self.model.drug_class_id = '';
                }
            }
        }, error => {
            self.model.drug_class_id = '';
        });
    }

    successProcess (modelType, result) {
        switch (modelType) {
            case 'Product unit':
                this.allUnits.push(result);
                this.model.product_unit = result.product_unit;
                this.addModel.product_unit = '';
                this.pordUnitSubmit = false;
                break;
            case 'Product type':
                this.allProductTypes.push(result);
                this.model.product_description_id = result.description_id;
                this.addModel.description_name = '';
                this.pordTypeSubmit = false;
                this.multiSelect.prodType = true;
                break;
            case 'Brand':
                this.allBrands.push(result);
                this.model.brand_id = result.brand_id;
                this.addModel.brand_name = this.addModel.brand_code = '';
                this.brandSubmit = false;
                this.multiSelect.brand = true;
                break;
            case 'Brand division':
                this.allDivisions.push(result);
                this.model.division_id = result.division_id;
                this.addModel.division_name = '';
                this.divisionSubmit = false;
                this.multiSelect.division = true;
                break;
            case 'Generic':
                this.allGenricNos.push(result);
                this.model.generic_id = result.generic_id;
                this.drugType = 'multi';
                this.model.drug_class_id = '';
                this.genericSubmit = false;
                this.multiSelect.generic = true;
                break;
            default:
                break;
        }
        this.globalHelper.toastrOpen('S', `${modelType} created successful`);
        this.showAddForm[modelType] = false;
    }

    saveModel (modelType) {
        this.globalHelper.toastrClear();
        const self = this;
        let apiMethod;
        switch (modelType) {
            case 'Product unit':
                apiMethod = this.productService.addNewProductUnit(this.addModel);
                this.addModel.prodUnitSubmitLoading = true;
                break;
            case 'Product type':
                apiMethod = this.productTypeService.createModel(this.addModel);
                this.multiSelect.prodType = false;
                this.addModel.prodSubmitLoading = true;
                break;
            case 'Brand':
                apiMethod = this.brandService.createModel(this.addModel);
                this.multiSelect.brand = false;
                this.addModel.brandSubmitLoading = true;
                break;
            case 'Brand division':
                apiMethod = this.brandDivisionService.createModel(this.addModel);
                this.multiSelect.division = false;
                this.addModel.brandivSubmitLoading = true;
                break;
            case 'Generic':
                apiMethod = this.genericNameService.createModel(this.addModel);
                this.multiSelect.generic = false;
                this.addModel.genSubmitLoading = true;
                break;
            default:
                break;
        }
        apiMethod.subscribe((result: any) => {
            self.addModel.prodUnitSubmitLoading = self.addModel.prodSubmitLoading = self.addModel.brandSubmitLoading = self.addModel.brandivSubmitLoading = self.addModel.genSubmitLoading = false;
            self.successProcess(modelType, result);
        });
    }

    onSubmit () {
        this.globalHelper.toastrClear();
        this.medFormLoading = true;
        const self = this;
        this.model.product_unit_count = this.model.product_unit_count || null;
        this.model.product_unit = this.model.product_unit || null;
        this.model['prescription'] = true;
        this.model['product_reorder_min'] = 1;
        this.model['product_reorder_max'] = 50;
        this.productService.createModel(this.model).subscribe((data: any) => {
            self.model = {
                purchase_vat_id: self.model.purchase_vat_id,
                sales_vat_id: self.model.sales_vat_id
            };
            self.closeModal();
            self.medFormLoading = false;
            self.globalHelper.toastrOpen('S', 'Product saved successful!');
        }, error => {
            self.medFormLoading = false;
        });
    }

    async getMasterRecords () {
        const self = this;
        const paramsOption = { params: this.globalHelper.convertObjectToParams({ status: 1 }) };
        this.multiselect.drug_name = this.multiselect.product_unit = false;
        await this.productService.getAllProductUnitLists(paramsOption).subscribe((result: any) => {
            self.allUnits = result;
            self.multiselect.product_unit = true;
        });
        await this.productTypeService.getModelLists().subscribe((allProductTypes: any) => {
            self.allProductTypes = allProductTypes;
            self.multiSelect.prodType = true;
            self.pordTypeSubmit = false;
        }, error => self.multiSelect.prodType = true);
        await this.brandService.getModelLists(paramsOption).subscribe((allBrands: any) => {
            self.allBrands = allBrands;
            self.multiSelect.brand = true;
            self.brandSubmit = false;
        }, error => self.multiSelect.brand = true);
        await this.brandDivisionService.getModelLists(paramsOption).subscribe((allDivisions: any) => {
            self.allDivisions = allDivisions;
            self.multiSelect.division = true;
            self.divisionSubmit = false;
        }, error => self.multiSelect.division = true);
        await this.genericNameService.getModelLists(paramsOption).subscribe((allGenericNos: any) => {
            self.allGenricNos = allGenericNos;
            self.multiSelect.generic = true;
            self.genericSubmit = false;
        }, error => self.multiSelect.generic = true);
        await this.vatService.getModelLists(paramsOption).subscribe((results: any) => {
            self.model['purchase_vat_id'] = Array.isArray(results) && results.length ? results[0].vat_id : '';
            self.model['sales_vat_id'] = self.model.purchase_vat_id;
        });
        await this.drugClassService.getModelLists(paramsOption).subscribe((allDrugs: any) => {
            self.allDrugs = allDrugs;
            self.multiselect.drug_name = true;
        });
    }

}
