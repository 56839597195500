import { Injectable } from '@angular/core';

import { BaseService } from '../../../services/base/base.service';

@Injectable()
export class OutPatientService extends BaseService {

    public modelPath = 'patient/appointments';

    getOutPaitents (optionParams) {
        return this.httpService.getMethod('patient/encounters/get_out_patients', optionParams);
    }

    getUnseenApptYears () {
        return this.httpService.getMethod('patient/encounters/get_unseen_appointment_years');
    }

    getOPLogId () {
        return this.httpService.getMethod('default/get_log');
    }

    reschduleAppt (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/bulk_reschedules`, bodyDatas);
    }

    cancelAppt (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/bulk_cancels`, bodyDatas);
    }

    getFutureAppointments (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getfuture_appointments`, optionParams);
    }

    getSelectedFutureAppointments (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getfuture_appointment_lists`, optionParams);
    }

    getFutureApptsWithDateWise (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getfuture_appointment_datewise`, optionParams);
    }

    getPastAppointments (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getpast_appointments`, optionParams);
    }

    getSelectedPastAppointments (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getpast_appointment_lists`, optionParams);
    }

    getPastApptsWithDateWise (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/getpast_appointment_datewise`, optionParams);
    }

    changeAppyStatus (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/changestatus`, bodyDatas);
    }

    getOPEncounterDetails (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_op_encounter_detail`, optionParams);
    }


    getChangeArrivedStatus (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/undo_arrived_status`, bodyDatas);
    }
}
