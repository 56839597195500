import { RouteInfo } from '../sidebar.metadata';

export const PATIENTROUTES: RouteInfo[] = [{
    path: '/patient/time-line',
    title: 'Timeline',
    icon: 'fas fa-chart-bar',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/vitals',
    title: 'Vitals',
    icon: 'fa fa-heartbeat',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/prescriptions',
    title: 'Prescriptions',
    icon: 'mdi mdi-prescription',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/documents',
    title: 'Documents',
    icon: 'fa fa-file-alt',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/consultant',
    title: 'Consultant',
    icon: 'fa fa-user-md',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/procedures',
    title: 'Procedures',
    icon: 'fas fa-procedures',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/encounters',
    title: 'Encounters',
    icon: 'mdi mdi-counter',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/notes',
    title: 'Notes',
    icon: 'fas fa-notes-medical',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/results',
    title: 'Results',
    icon: 'fa fa-file-alt',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/view',
    title: 'Details',
    icon: 'mdi mdi-account-card-details', // 'fas fa-address-card',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/billing',
    title: 'Billing',
    icon: 'icon-wallet',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/alerts-and-allergies',
    title: 'Alerts & Allergies',
    icon: 'mdi mdi-alert-circle',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/request-and-result',
    title: 'Request & Results',
    icon: 'mdi mdi-file-chart',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}, {
    path: '/patient/share-modules',
    title: 'Share Modules',
    icon: 'icon-share',
    class: '',
    extralink: false,
    disabled: true,
    submenu: []
}];
