import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {

    public baseUrl;
    public apiVersion;
    public patientPathCount;
    public wsUrl;
    public wsKey;
    public wsCluster;
    public wsHost;
    public wsPort;
    public wssPort;
    public httpHost;
    public forceTLS;

    constructor() { }
}
