import { Injectable } from '@angular/core';

import { LabService } from '../../../services/lab.service';

@Injectable()
export class PatientRequestService extends LabService {

    public modelPath = 'lab/patient_request';

    getBodyDatas () {
        return {
            columns: [{
                data: 'lab_pat_requests.pat_request_id',
                name: 'lab_pat_requests.pat_request_id',
                searchable: false,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'items',
                name: 'items',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'fullname',
                name: 'fullname',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'request_datetime',
                name: 'request_datetime',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'createdby_name',
                name: 'createdby_name',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'consultant_name',
                name: 'consultantname',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            },	{
                data: 'requestStateName',
                name: 'requestStateName',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [{
                column: 0,
                dir: 'desc'
            }],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getPatReqItems(optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_pat_request_based_on_module`, optionParams);
    }

    printPatReqs(optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/print_request_item`, optionParams);
    }

    printHeader() {
        return {};
    }

    printFooter(printSetting) {
        if (+printSetting.verify_by_sign === 1 || +printSetting.lab_technologist_sign === 1) {
            return [{
                columns: [{
                    text: +printSetting.verify_by_sign === 1 ? 'Verified by' : '',
                    margin: [20, 50, 0, 150],
                    alignment: 'left'
                }, {
                    text: +printSetting.lab_technologist_sign === 1 ? 'lab Technologist' : '',
                    margin: [0, 50, 20, 150],
                    alignment: 'right'
                }]
            }];
        } else {
            return {};
        }
    }

    printStyle() {
        return {
            headerStyle: {
                margin: [],
            },
            h1: {
                fontSize: 12,
                bold: true,
            },
            h2: {
                fontSize: 10,
                bold: true,
            },
            th: {
                fontSize: 8,
                bold: true,
                margin: [0, 3, 0, 3],
            },
            td: {
                fontSize: 7,
                margin: [0, 3, 0, 3],
            },
            normaltxt: {
                fontSize: 8,
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 0, 5, 3],
            },
            footertxt: {
                fontSize: 6,
                margin: [20, 0, 0, 0],
            },
            prescriptionTh: {
                bold: true,
                fontSize: 10,
            },
            fs10: {
                fontSize: 10,
            },
            fa: {
                font: 'FontAwesome'
            },
            fab: {
                font: 'FaBrand'
            },
            far: {
                font: 'FaRegular'
            },
            fas: {
                font: 'Fasolid'
            }
        };
    }

    async getPMContent(printDatas) {
        let left = {}, center = {};
        const printSetting = printDatas.patient_request.patientResultPrintSetting,
        content = [],
        patientDetails = [],
        perPatientInfo = [],
        perPageItem = [],
        statusLog = [];
        if (printSetting && printSetting.logo === 1 && printSetting.logoAlign === 'right') {
            content.push({
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width: 150,
                height: 50,
                alignment: 'right'
            });
        }
        if (printSetting.logo === 1 && printSetting.logoAlign === 'left') {
            left = {
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width: 150,
                height: 50,
            };
        }
        if (printSetting.logo === 1 && printSetting.logoAlign === 'center') {
            center = {
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width: 150,
                height: 50,
            };
        }
        content.push({
            text: this.globalHelper.getMomentDatas('DD-MMMM-YYYY'),
            alignment: 'right',
        });
        let bar_image, qrcode_img;
        const barCodeElemt = document.getElementById(printDatas.patient.patient_global_int_code);
        const qrCodeElemt = document.getElementById(`qrcode-${printDatas.patient.patient_global_int_code}`);
        if (printDatas.patient.patient_global_int_code && document.contains(barCodeElemt)) {
            bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
        }
        if (qrCodeElemt) {
            qrcode_img = {
                border: [false, true, false, true],
                image : this.imgExport(qrCodeElemt.getElementsByTagName('img')[0], 'qrcode'), // QR code image
                width : 50,
                height : 60,
            };
        } else {
            qrcode_img = {
                border: [false, true, false, true],
                text: ' ',
                width : 30,
                height : 60,
            };
        }
        content.push({
            layout: 'noBorders',
            table: {
                widths: ['33%', '33%', '*'],
                body: [
                    [
                        left,
                        center,
                        {
                            image: bar_image,
                            width: 150,
                            height: 50,
                            alignment: 'right',
                        },
                    ],
                ],
            }
        });
        let catgDiv = [{}, {}];
        if (printDatas.patient.patient_category && printDatas.patient.patient_category.patient_short_code &&
            printDatas.patient.patient_category.patient_category_color) {
            const catgSC = this.globalHelper.convUC(printDatas.patient.patient_category.patient_short_code);
            catgDiv = [{
                canvas: [{
                    type: 'ellipse',
                    x: 8,
                    y: 5,
                    color: printDatas.patient.patient_category.patient_category_color,
                    r1: 10,
                    r2: 10,
                }]
            }, {
                text: catgSC,
                color: '#ffffff',
                relativePosition: { x: catgSC.length === 1 ? -4 : -7, y: -1 },
            }];
        }
        if (printDatas.status_log) {
            for (let i = 0; i < printDatas.status_log.length; i++) {
                statusLog.push([{
                    text: `${printDatas.status_log[i].requestStateName}`,
                }, {
                    text: ' : '
                }, {
                    margin: [0, 1, 0, 0],
                    text: this.globalHelper.getMomentDatas('D-M-Y h:mm A', printDatas.status_log[i].created_at),
                    colSpan: 2
                }]);
            }
        }
        patientDetails.push([
            qrcode_img,
            {
                border: [false, true, false, true],
                image: this.textExport(qrCodeElemt ? printDatas.patient.patient_global_int_code : ''),
                height: 60
            }, {
            border: [false, true, true, true],
            layout: 'noBorders',
            table: {
                widths: [55, 1, '*'],
                body: [[{
                    text: `${this.globalHelper.convUC(printDatas.patient.fullname)}${
                        (printDatas.patient.patient_age && printDatas.patient.patient_age.age)
                        ? ` / ${printDatas.patient.patient_age.age}y` : ''} ${printDatas.patient.patient_gender ? '/ ' + this.globalHelper.getAllGenders(printDatas.patient.patient_gender) : ''}`,
                    style: ['td', 'h1'],
                    colSpan: 3,
                    margin: [0, 0, 0, 5],
                }, {}, {}],
                [printDatas.patient.patient_global_int_code].concat(catgDiv),
                [{
                    text: 'Consultant',
                    margin: [0, 4, 0, 0]
                }, {
                    text : ':',
                    margin: [0, 4, 0, 0]
                }, {
                    margin: [0, 4, 0, 0],
                    text: this.globalHelper.convUC(printDatas.patient_request.consultant_name),
                }],
                [{
                    text: 'Address',
                    margin: [0, 4, 0, 0]
                }, {
                    text : ':',
                    margin: [0, 4, 0, 0]
                }, {
                    margin: [0, 4, 0, 0],
                    text: printDatas.patient_request.patientAddress.addr_current_address || '-',
                }]
            ]
            },
        }, {
            border: [false, true, false, true],
            margin: [10, 0, 0, 0],
            layout: 'noBorders',
            table: {
                widths: [ '*', 5, 30, 55],
                body: statusLog
            }
        }]);
        perPatientInfo.push({
            layout: {
                hLineColor: function (i, node) {
                    return '#949494';
                },
                vLineColor: function (i, node) {
                    return '#949494';
                }
            },
            table: {
                headerRows: 1,
                widths: [50, 15, '*', 200],
                body: patientDetails,
            },
        });
        content.push(perPatientInfo);
        perPageItem.push([{
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: 'Investigation',
            alignment: 'left',
            style: 'prescriptionTh',
        }, {
            margin: [0, 5, 0, -5],
            border: [false, false, false, false],
            rowspan: 2,
            text: '', // Analysed
            alignment: 'center',
            style: 'prescriptionTh',
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            text: '', // printDatas.module_id !== 3? 'Value'
            alignment: 'center',
            rowspan: 2,
            style: 'prescriptionTh',
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            text: `${printDatas.module_id !== 3 ? 'Value   ' : ''}Unit`,
            alignment: 'center',
            rowspan: 2,
            style: 'prescriptionTh',
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            text: 'Interpretation',
            alignment: 'center',
            rowspan: 2,
            style: 'prescriptionTh',
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            text: 'Reference',
            alignment: 'center',
            rowspan: 2,
            style: 'prescriptionTh',
        }, {
            margin: [5, 5, 0, -5],
            border: [false, false, false, false],
            text: 'Remarks',
            alignment: 'center',
            rowspan: 2,
            style: 'prescriptionTh',
        }]);
        const headerBottom = [];
        for (let i = 0; i < 7; i++) {
            const [canvasObj, normalObj] = [{
                margin: [0, 0, 0, -1],
                border: [false, false, false, true],
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 80, y2: 5, lineWidth: 2 }], // (i === 1 || i === 2 || i === 3) ? (i === 1 ? 45 : 35) : (i === 6 || i === 4 )? 100 : 70
            }, {
                margin: [0, 0, 0, 0],
                border: [false, false, false, true],
                text: '',
            }];
            headerBottom.push((i === 1 || i === 2) ? normalObj : canvasObj); // i === 2 && printDatas.module_id === 3
        }
        perPageItem.push(headerBottom);
        printDatas.items.map((reqItemDatas, reqIndex) => {
            if (reqItemDatas && Array.isArray(reqItemDatas.group_datas) && reqItemDatas.group_datas.length ) {
                reqItemDatas.group_datas.map((itemDatas, itemIndex) => {
                    let prodDetails = [], freqDetails = [], indexTotal = 0;
                    if (reqIndex > 0) {
                        for (let i = 0; i < reqIndex ; i++) {
                            if (printDatas.items[i]) {
                                indexTotal += printDatas.items[i].group_datas.length;
                            }
                        }
                    }
                    prodDetails = [{
                        border: [false, false, false, false],
                        text: [
                            {
                                text: indexTotal + itemIndex + 1,
                                fontSize: 10,
                            }, {
                                text: `. ${itemDatas.itemName}`,
                                style: 'h2',
                            }
                        ],
                        alignment: 'left',
                        style: ['td'],
                        margin: [0, 0, 2, 15]
                    }];
                    const analysed = {
                        border: [false, false, false, false],
                        text: '',
                        alignment: 'center',
                        margin: [0, 0, 0, 0]
                    };
                    let [fontClass, iconClass] = ['', ''];
                    const iconEmptyvalue = itemDatas.itemType === 'number' ? '-' : '';
                    if (itemDatas.itemType === 'number' && itemDatas.referenceValue
                        && itemDatas.referenceValue.font_name) {
                        [fontClass, iconClass] = itemDatas.referenceValue.font_name.split(' ');
                        // 	analysed = {
                    // 		border: [false, false, false, false],
                    // 		text: this.findSymbolForClass(iconClass),
                    // 		style: fontClass,
                    // 		alignment: 'center',
                    // 		fontSize: 10,
                    // 		margin: [0, 0, 2, 15]
                    }
                    // } else {

                    // 	analysed = {
                    // 		border: [false, false, false, false],
                    // 		text:  itemDatas.itemType === 'number' ? '-' : 'N',
                    // 		alignment: 'center',
                    // 		style: ['td', 'fs10'],
                    // 		margin: [0, 0, 2, 15]
                    // 	}
                    // }
                    freqDetails = [
                        analysed,
                        {
                            border: [false, false, false, false],
                            text: '', // printDatas.module_id !== 3 ? (itemDatas.value || '-')
                            // alignment: 'center',
                            // style: ['td', 'fs10'],
                            margin: [0, 0, 0, 0] // [0, 0, 2, 15]
                        }, {
                            border: [false, false, false, false],
                            text: [
                                printDatas.module_id !== 3  ? (itemDatas.value ? itemDatas.value + ' ' : '- ') : '',
                                itemDatas.itemUnit ? itemDatas.itemUnit : '-',
                            ],
                            alignment: 'center',
                            style: ['td', 'fs10'],
                            margin: [0, 0, 2, 15]
                        }, {
                            border: [false, false, false, false],
                            text: [
                                (itemDatas.itemType === 'number' && itemDatas.referenceValue && itemDatas.referenceValue.font_name) ?
                                    {text: this.findSymbolForClass(iconClass), style: fontClass} : iconEmptyvalue,
                                `${itemDatas.itemType === 'number' ? (itemDatas.referenceValue && itemDatas.referenceValue.name ?
                                    ' ' + itemDatas.referenceValue.name : iconEmptyvalue) : (itemDatas.interpretation || '-')}`,
                            ],
                            // text: [itemDatas.itemType === 'number' ? (itemDatas.referenceValue ?
                            // 	(`${itemDatas.referenceValue.font_name ?  fontIcon : '-'} `+ itemDatas.referenceValue.name) : '-') : (itemDatas.interpretation || '-')],
                            alignment: 'center',
                            style: ['td', 'fs10'],
                            margin: [0, 0, 2, 15]
                        }, {
                            border: [false, false, false, false],
                            text: itemDatas.itemType === 'number' ? (itemDatas.normalValue ?
                                `${itemDatas.normalValue.from_value} to ${itemDatas.normalValue.to_value}` :
                                '-') : (itemDatas.item_reference || '-'),
                            alignment: 'center',
                            style: ['td', 'fs10'],
                            margin: [0, 0, 2, 15]
                        }, {
                            border: [false, false, false, false],
                            text: itemDatas.remarks ? itemDatas.remarks : '-',
                            alignment: 'center',
                            style: ['td', 'fs10'],
                            margin: [0, 0, 0, 15]
                        }
                    ];
                    perPageItem.push(Array.prototype.concat(prodDetails, freqDetails));
                });
            }
        });
        content.push({
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 ? 3 : 1;
                },
            },
            table: {
                headerRows: 2,
                widths: ['20%', '0%', '0%', '20%', '20%', '20%', '20%'],
                body: perPageItem,
            }
        });
        return content;
    }

    imgExport(imgID, codeType?) {
        const img = typeof imgID === 'string' ? document.getElementById(imgID) : imgID,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
        let imgwidth, imgheight;
        if (typeof imgID !== 'string') {
            if (codeType) {
                canvas.height = canvas.width = 0;
                imgwidth = img.width;
                imgheight = img.height;
                canvas.width = imgwidth;
                canvas.height = imgheight + 15;
                ctx.translate(imgwidth / 2, (imgheight + 15) / 2);
                ctx.rotate(90 * Math.PI / 180);
            } else {
                canvas.width = 68;
                canvas.height = 280;
                ctx.translate(72, 75);  // width
                ctx.rotate(Math.PI * 3 / 2);
                ctx.translate(-210, -75); // Height
            }
        } else {
            canvas.width = img['width'];
            canvas.height = img['height'];
        }
        if (codeType) {
            ctx.drawImage(img, -(imgwidth / 2), -(imgheight / 2));
        } else {
            ctx.drawImage(img, 0, 0);
        }
        return canvas.toDataURL('image/png');
    }

    textExport = value => {
        const text = value.split('').join(' ');
        const canvas = document.createElement('canvas');
        canvas.width = 50;
        canvas.height = 100;
        const ctx = canvas.getContext('2d');
        ctx.font = 'bold 10px Arial';
        ctx.translate(9, 89);
        ctx.rotate(-0.5 * Math.PI);
        ctx.fillStyle = '#000';
        ctx.fillText(text, 0, 0);
        return canvas.toDataURL();
    }

    findSymbolForClass (selector) {
        let result = '';
        const sheets = document.styleSheets;
        for (let sheetNr = 0; sheetNr < sheets.length; sheetNr++) {
            const content = this.findCSSRuleContent(sheets[sheetNr], selector);
            if (content && typeof content === 'string') {
                result = content.slice(1, content.length - 1);
                break;
            }
        }
        return result;
    }

    findCSSRuleContent(mySheet, selector) {
        let ruleContent = '';
        const rules = mySheet.cssRules ? mySheet.cssRules : mySheet.rules;
        for (let i = 0; i < rules.length; i++) {
            const iconCls = rules[i].selectorText;
            if (iconCls && iconCls.indexOf(selector) >= 0) {
                ruleContent = rules[i].style.content;
                break;
            }
        }
        return ruleContent;
    }

    getBarCodeContent(printDatas) {
        let bar_image;
        const barCodeElemt = document.getElementById(printDatas.patient.patient_global_int_code);
        if (printDatas.patient.patient_global_int_code && document.contains(barCodeElemt)) {
            bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
        }
        return [{
            image: bar_image,
        }];
    }
}
