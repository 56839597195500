import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
    OwlDateTimeComponent,
    OWL_DATE_TIME_FORMATS,
    DateTimeAdapter,
    OWL_DATE_TIME_LOCALE,
    OwlDateTimeFormats
} from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Moment } from 'moment';

import { GlobalHelper } from '../../helper/global.helper';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'MM/YYYY',
    fullPickerInput: 'l LT',
    datePickerInput: 'MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
    selector: 'app-month-year-picker',
    templateUrl: './month-year-picker.component.html',
    styleUrls: ['./month-year-picker.component.css'],
    providers: [{
        provide: DateTimeAdapter,
        useClass: MomentDateTimeAdapter,
        deps: [OWL_DATE_TIME_LOCALE]
    }, {
        provide: OWL_DATE_TIME_FORMATS,
        useValue: MY_MOMENT_DATE_TIME_FORMATS
    }]
})
export class MonthYearPickerComponent implements OnInit {

    @Input('pickerVal') pickerVal;
    @Input('dpDatas') dpDatas;
    @Output() pickerChange = new EventEmitter<string>();
    model: any = {};

    constructor(private globalHelper: GlobalHelper) {}

    ngOnInit() {
        this.model.minDate = new Date(this.dpDatas);
        let ctrlValue;
        if (this.pickerVal) {
            this.model.dateTime = new FormControl(this.globalHelper.getMomentDatas());
            ctrlValue = this.model.dateTime.value;
            ctrlValue.month(this.globalHelper.getMomentDatas('month', this.pickerVal));
            ctrlValue.year(this.globalHelper.getMomentDatas('year', this.pickerVal));
            this.model.dateTime.setValue(ctrlValue);
        } else {
            this.model.dateTime = new FormControl();
        }
    }

    chosenYearHandler (normalizedYear: Moment) {
        const ctrlValue = this.model.dateTime.value || this.globalHelper.getMomentDatas();
        ctrlValue.year(normalizedYear.year());
        this.model.dateTime.setValue(ctrlValue);
    }

    chosenMonthHandler (normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
        const ctrlValue = this.model.dateTime.value;
        ctrlValue.month(normalizedMonth.month());
        this.model.dateTime.setValue(ctrlValue);
        const returnVal: any = this.globalHelper.getMomentDatas('YYYY-MM-DD', this.model.dateTime.value);
        this.pickerChange.next(returnVal);
        datepicker.close();
    }

}
