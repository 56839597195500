export const CHART_COLORS: Array<any> = [
    [{
        backgroundColor: 'transparent',
        borderColor: '#7460ee',
        pointBackgroundColor: '#7460ee',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#7460ee'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#f62d51',
        pointBackgroundColor: '#f62d51',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#f62d51'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#36bea6',
        pointBackgroundColor: '#36bea6',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#36bea6'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#2962ff',
        pointBackgroundColor: '#2962ff',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#2962ff'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#ffbc34',
        pointBackgroundColor: '#ffbc34',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#ffbc34'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#6c757d',
        pointBackgroundColor: '#6c757d',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#6c757d'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#3b5998',
        pointBackgroundColor: '#3b5998',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#3b5998'
    }], [{
        backgroundColor: 'transparent',
        borderColor: '#ea4c89',
        pointBackgroundColor: '#ea4c89',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#ea4c89'
    }]
];
