import { Directive, ElementRef, HostListener, Input  } from '@angular/core';

@Directive({
  selector: '[avoid-selected-char]'
})
export class AvoidSelectedCharDirective {
  @Input() customKeys: any;
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    // The below conditions are not Allowed Keys
    if (this.customKeys && this.customKeys.indexOf(e.keyCode) !== -1) {
      return false;
    }
    if (// Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right & space
      (e.keyCode >= 35 && e.keyCode <= 39) || e.keyCode <= 32) {
      // let it happen, don't do anything
      return;
    }
  }

}
