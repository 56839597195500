import { Directive, ElementRef, AfterViewInit } from '@angular/core';

import { GlobalHelper } from '../../../helper/global.helper';

@Directive({
    selector: '[check-access]'
})
export class CheckAccessDirective implements AfterViewInit {

    constructor(private elementRef: ElementRef, private globalHelper: GlobalHelper) {}

    ngAfterViewInit () {
        const routerLinkVal = this.elementRef.nativeElement.getAttribute('routerLink');
        if (routerLinkVal) {
            this.findAccessAndRemoveElement (routerLinkVal, 'resource_url');
        } else {
            this.checkRLVal();
        }
    }

    checkRLVal () {
        let routerLinkVal = this.elementRef.nativeElement.getAttribute('href');
        if (routerLinkVal && routerLinkVal !== 'javascript:void(0)') {
            if (!this.elementRef.nativeElement.classList.contains('has-arrow')) {
                const linkArr = routerLinkVal.split('/');
                if (linkArr.length <= 3) {
                    this.findAccessAndRemoveElement (routerLinkVal, 'resource_url');
                } else {
                    routerLinkVal = `/${linkArr[1]}/${linkArr[2]}`;
                    this.findAccessAndRemoveElement (routerLinkVal, 'resource_url');
                }
            }
        } else {
            routerLinkVal = this.elementRef.nativeElement.getAttribute('data-access');
            if (routerLinkVal) {
                this.findAccessAndRemoveElement (routerLinkVal, 'resource_name');
            } else {
                // routerLinkVal = clickedElemt.text();
            }
        }
    }

    findAccessAndRemoveElement (routerLinkVal, fieldName) {
        const userAccessUrls = this.globalHelper.getCurData('user-access-urls');
        if (fieldName === 'resource_url') { routerLinkVal = routerLinkVal.slice(1); }
        if (routerLinkVal.search('add-other-charges') !== -1) {
            console.log('yes');
        }
        if (Array.isArray(userAccessUrls) && userAccessUrls.length) {
            if (!userAccessUrls.some(accDatas => accDatas[fieldName] === routerLinkVal)) {
                this.removeElemts();
            }
        } else {
            this.removeElemts();
        }
    }

    removeElemts () {
        if (this.elementRef.nativeElement.className.search('sidebar-link') === -1) {
            this.elementRef.nativeElement.remove();
        } else {
            this.elementRef.nativeElement.parentElement.remove();
        }
    }

}
