import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { GlobalHelper } from '../../helper/global.helper';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input('options') options;
    @Input('showMobileMenu') showMobileMenu;
    @Output() showMobileMenuChange = new EventEmitter<boolean>();

    public defaultSidebar: any;
    public isCollapsed = false;
    public expandLogo = false;
    public idleOnFunc: any = {};

    constructor(private idle: Idle, private keepalive: Keepalive,
        public globalHelper: GlobalHelper) {
        const self = this;
        const userTimeout = this.globalHelper.getCurData('userTimeout');
        if (userTimeout) {
            idle.setIdle(parseInt(userTimeout) * 60);
            idle.watch();
        }
        idle.setTimeout(5);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idleOnFunc.start = idle.onIdleStart.subscribe(() => {
            self.globalHelper.toastrClear();
            self.globalHelper.toastrOpen('W', 'Your session was will expired soon...', {
                progressBar: true,
                timeOut: 5000
            });
            console.log('onIdleStart');
        });
        this.idleOnFunc.end = idle.onIdleEnd.subscribe(() => {
            self.globalHelper.toastrClear();
            // self.idleOnFunc.timeout.unsubscribe();
            // idle.clearTimeout();
            console.log('onIdleEnd');
        });
        // idle.onTimeoutWarning.subscribe((countdown) => console.log(countdown) );
        this.idleOnFunc.timeout = idle.onTimeout.subscribe(() => {
            // logout
            self.globalHelper.logout();
            console.log('onTimeout');
        });
        keepalive.interval(15);
    }

    ngOnInit() {
        this.defaultSidebar = this.options.sidebartype;
    }

    Logo() {
        this.expandLogo = !this.expandLogo;
    }

    changeMenu () {
        this.showMobileMenu = !this.showMobileMenu;
        this.showMobileMenuChange.emit(this.showMobileMenu);
    }

    toggleSidebarType() {
        switch (this.options.sidebartype) {
            case 'full':
            case 'iconbar':
                this.options.sidebartype = 'mini-sidebar';
                break;

            case 'overlay':
                this.showMobileMenu = !this.showMobileMenu;
                break;

            case 'mini-sidebar':
                if (this.defaultSidebar === 'mini-sidebar') {
                    this.options.sidebartype = 'full';
                } else {
                    this.options.sidebartype = this.defaultSidebar;
                }
                break;

            default:
        }
    }

    ngOnDestroy () {
        this.idleOnFunc.start.unsubscribe();
        this.idleOnFunc.end.unsubscribe();
        this.idleOnFunc.timeout.unsubscribe();
    }

}
