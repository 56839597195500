import { Pipe, PipeTransform } from '@angular/core';
import { CompleterService, CompleterData, CompleterItem } from 'ng2-completer';

@Pipe({
    name: 'freqSrcDatas'
})
export class FreqSrcDatasPipe implements PipeTransform {

    constructor(private completerService: CompleterService) {}

    transform(value: any, args?: any): any {
        const freqVal: CompleterData = this.completerService.local(value, null, null);
        return freqVal;
    }

}
