// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://hmsapi.ark/',
  baseUrl: 'http://hmsapi.lumen/',
  // baseUrl: 'http://service.sumanas.in/',
  apiVersion: 'v1',
  patientPathCount: 2,
  // wsUrl: 'http://13.127.60.56:8082/'
  wsUrl: 'ws://localhost:8443/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
