import { AfterViewInit, Directive, OnInit, ElementRef } from '@angular/core';
import * as cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import * as dicomParser from 'dicom-parser';

import { CORNERSTONE_CONFIG } from 'src/app/constants/cornerstone-config';

cornerstoneWADOImageLoader.webWorkerManager.initialize(CORNERSTONE_CONFIG);

@Directive({
    selector: '[appScanViewer]'
})

export class ScanViewerDirective implements OnInit, AfterViewInit {
    imgOrginialSrc;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
        cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    }

    ngAfterViewInit(): void {
        const targetElId = this.el.nativeElement.id,
            imgId = this.el.nativeElement.children[0].id;

        this.imgOrginialSrc = $(`#${targetElId} #${imgId}`).attr('src');
        $(`#${targetElId} #${imgId}`).attr('src', 'assets/images/background/circle-loader.gif');
        $(`#${targetElId} #${imgId}`).css('background', '#c8c7c7');
        $(`#${targetElId} #${imgId}`).addClass('element-disabled');
        this.scanImageLoading();
    }

    scanImageLoading() {
        const element = document.createElement('div') as any;
        element.height = 100;
        element.width = 100;
        element.style.visibility = 'hidden';
        document.body.appendChild(element);
        cornerstone.enable(element);

        cornerstone.loadAndCacheImage('wadouri:' + this.imgOrginialSrc).then(imageData => {
            cornerstone.displayImage(element, imageData);
            setTimeout(() => {
                this.convertCanvasToImage(element);
            }, 2000);
        }).catch( error => { console.error(error); });
    }

    convertCanvasToImage(element) {
        const canvas = element.getElementsByTagName('canvas');
        canvas[0].toBlob((blob) => {
            const targetElId = this.el.nativeElement.id,
                imgId = this.el.nativeElement.children[0].id;
            const url = URL.createObjectURL(blob);
            $(`#${targetElId} #${imgId}`).attr('src', url);
            $(`#${targetElId} #${imgId}`).removeClass('element-disabled');
            document.body.removeChild(element);
        });
    }
}
