import { Component, OnInit, Input } from '@angular/core';
import { GlobalHelper } from 'src/app/helper/global.helper';
import * as _ from 'lodash';

@Component({
  selector: 'app-result-print-template',
  templateUrl: './result-print-template.component.html',
  styleUrls: ['./result-print-template.component.css']
})
export class ResultPrintTemplateComponent implements OnInit {

  @Input() modelValue: any;
  resultPrintData;
  printSetting;
  today = this.globalHelper.getMomentDatas('DD-MMMM-YYYY');
  orgId = this.globalHelper.getCurData('curOrgId');
  ajHospitalOrgId = _.get(this.globalHelper.getConstantsValue('app'), 'ajHospitalOrgId', '');
  resultItems = [];

  constructor(private globalHelper: GlobalHelper) {}

  ngOnInit() {
    this.resultPrintData = this.modelValue;
    this.printSetting = this.modelValue.patient_request.patientResultPrintSetting;
    this.resultItems = this.resultPrintData.result_items.map(lists => {
      const groupData = Object.keys(lists).filter(key => key !== 'item' && key !== 'packName');
      return {
        packName: lists.packName,
        item: _.get(lists, `item[0].group_datas`, []),
        group_datas: groupData.map(group => {
          return { name: _.get(lists, `${group}.name`, ''), item: _.get(lists, `${group}.item[0].group_datas` , [])};
        })
      };
    });
  }

}
