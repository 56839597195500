import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-options',
  templateUrl: './export-options.component.html',
  styleUrls: ['./export-options.component.css']
})
export class ExportOptionsComponent implements OnInit {

  @Output() modalSaveCB = new EventEmitter<string>();
  @ViewChild('exportEleRef') exportEleRef: NgbModalRef;
  modelRef;
  model: any = {};
  constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    const self = this;
    this.model.exportOption = 'DN';
    setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true
            };
            self.modelRef = self.modalService.open(self.exportEleRef, option);
            self.modelRef.result.then((result) => {}, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().exportModal = false;
                }
            });
        });
  }

  getParentComponent() {
      return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
  }

  closeModal() {
    this.getParentComponent().exportModal = false;
    this.modelRef.close();
  }

  saveCallback() {
        this.modalSaveCB.next(this.model);
        this.closeModal();
    }
}
