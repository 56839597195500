import { Injectable } from '@angular/core';

import { LabService } from '../../../services/lab.service';

@Injectable()
export class LabBillingService extends LabService {

    public modelPath = 'lab/billing';

    getPaymentDetails (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/payment_details_based_encounter`, optionParams);
    }

    getInvestingCharge(optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_investigation_details`, optionParams);
    }

    printInventryBill (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/print_inventry_bill`, optionParams);
    }

    getAllInventryBill (optionParams) {
        return this.httpService.getMethod(`lab/patient_request/get_patient_request_based_on_encounter_id`, optionParams);
    }

    printHeader() {
        return {};
    }

    printFooter() {
        return {};
    }

    printStyle() {
        return {};
    }

    async getPMContent(reportList, branchName, generatedBy, totalValues) {
        let mode = '';
        let paymentDetails = '';
        if (reportList.payment_mode === 'CA') {
            mode = 'Cash';
        } else if (reportList.payment_mode === 'CH') {
            mode = 'Cheque';
            paymentDetails = 'Bank Name = ' + reportList.bank_name + ', Cheque Number =' + reportList.bank_number + 'Date =' + this.globalHelper.getMomentDatas('app-date-time', reportList.bank_date);
        } else if (reportList.payment_mode === 'ON') {
            mode = 'Online';
            paymentDetails = 'Bank Name = ' + reportList.bank_name + ', Ref No =' + reportList.bank_number + 'Date =' + this.globalHelper.getMomentDatas('app-date-time', reportList.bank_date);
        } else {
            mode = 'Card';
            paymentDetails = 'Card Type =' + reportList.card_type + ', Card Number =' + reportList.card_number;
        }
        return [{
            border: [false, true, false, false],
            layout: {
                paddingLeft: function (i, node) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 2;
                },
                paddingTop: function (i, node) {
                    return 0;
                },
                paddingBottom: function (i, node) {
                    return 0;
                },
            },
            table: {
                widths: [75, 5, '*', 65, 5, '*', 65, 5, 'auto'],
                body: [
                    [{
                        border: [false, false, false, false],
                        text: 'Patient Name',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: reportList.patient.fullname || '-',
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: 'UHID',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: reportList.patient.patient_global_int_code || '-',
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: 'Age / Sex',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: `${reportList.patient.patient_age && reportList.patient.patient_age.age ?
                            reportList.patient.patient_age.age + ' Y' : '-'} / ${this.globalHelper.getParticularArrVal('gender', 'value', reportList.patient.patient_gender) || '-'}`,
                        style: 'normaltxt'
                    }], [{
                        border: [false, false, false, false],
                        text: 'Patient Category',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: reportList.patient.patient_category.patient_cat_name,
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: 'Paid Date',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: this.globalHelper.getMomentDatas('app-date-time', reportList.payment_date),
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: 'Paid Amount',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: (parseFloat(reportList.payment_amount)).toFixed(2),
                        style: 'normaltxt'
                    }], [{
                        border: [false, false, false, false],
                        text: 'Payment Mode',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: mode,
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: 'Payment Details',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: ':',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: paymentDetails || '-',
                        style: 'normaltxt'
                    }, {
                        border: [false, false, false, false],
                        text: '',
                        style: 'h2',
                        margin: [-5, 0, 0, 0],
                    }, {
                        text: '',
                        border: [false, false, false, false],
                        style: 'h2'
                    }, {
                        border: [false, false, false, false],
                        text: '',
                        style: 'normaltxt'
                    }]
                ]
            },
        }];
    }

}
