import { RouteInfo } from '../sidebar.metadata';

export const MYWORKSGROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'OP Reports',
        icon: 'mdi mdi-file-document',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/my-works/op-payments',
                title: 'OP Payments',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/op-summary-reports',
                title: 'OP Summary Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/op-non-recurring-charge-reports',
                title: 'OP Non-Recurring Charge Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/procedure-patient-report',
                title: 'Procedure Patient Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/op-daily-report',
                title: 'OP Daily Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
        ],
    },
    {
        path: '',
        title: 'IP Reports',
        icon: 'mdi mdi-file-document',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/my-works/ip-bill-status',
                title: 'IP Bill Status',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/discharged-patient-bill',
                title: 'Discharged Patient Bill',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/ip-doctor-pay',
                title: 'IP Doctor Pay',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/ip-income-report',
                title: 'IP Income Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/non-recurring-charge',
                title: 'Non Recurring Charge Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/recurring-charge',
                title: 'Recurring Charge Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/discharged-patient-dues',
                title: 'Discharged Patient Dues',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/discharged-patient-pharmacy-charge',
                title: 'Discharged Pharmacy Charge',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
        ],
    },
    {
        path: '',
        title: 'Investigation Reports',
        icon: 'mdi mdi-file-document',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/my-works/attendee-report',
                title: 'Attendee Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/pending-amount-report',
                title: 'Pending Amount Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/pending-results',
                title: 'Pending Results',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/payment-report',
                title: 'Payment Report',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
        ],
    },
    {
        path: '/my-works/patient-merge',
        title: 'Patients Merge',
        icon: 'mdi mdi-arrow-compress-all',
        class: '',
        extralink: false,
        submenu: [],
    },
    {
        path: '/my-works/audit-log',
        title: 'Audit Log',
        icon: 'fas fa-history',
        class: '',
        extralink: false,
        submenu: [],
    },
    {
        path: '',
        title: 'My Shortcuts',
        icon: 'mdi mdi-file-document',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/my-works/add-consultation-visit',
                title: 'Add Consultation Visit',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/add-procedure',
                title: 'Add Procedure',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/add-other-charge',
                title: 'Add Charges',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/add-note',
                title: 'Add Notes',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
            {
                path: '/my-works/add-vital',
                title: 'Add Vitals',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
            },
        ],
    },
    {
        path: '/my-works/custom-query',
        title: 'Custom Query',
        icon: 'fab fa-searchengin',
        class: '',
        extralink: false,
        submenu: [],
    },
    {
        path: '/my-works/indent-report',
        title: 'Indent Report',
        icon: 'mdi mdi-file-document',
        class: '',
        extralink: false,
        submenu: [],
    },
    {
        path: '/my-works/pharmacy-indent',
        title: 'Pharmacy Indent',
        icon: 'fas fa-prescription-bottle-alt',
        class: '',
        extralink: false,
        submenu: [],
    },
    {
        path: '/my-works/my-report-logs',
        title: 'Exported Report Logs',
        icon: 'mdi mdi-file-document',
        class: '',
        extralink: false,
        submenu: [],
    },
];
