import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewContainerRef, ElementRef, AfterViewInit } from '@angular/core';
import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
    selector: 'app-lab-payment-print',
    templateUrl: './lab-payment-print.component.html',
    styleUrls: ['./lab-payment-print.component.css']
})
export class LabPaymentPrintComponent implements OnInit, AfterViewInit {
    @Input('options') options;
    @Output() modalSaveCB = new EventEmitter<string>();
    paymentPrint: any = null;
    voucherPrint: any = null;
    patientDatas: any = null;
    printLogo = null;
    @ViewChild('printTemp') printTemp: ElementRef;
    curOrgId = this.globalHelper.getCurData('curOrgId');
    ajHospitalOrgId = this.globalHelper.getConstantsValue('app').ajHospitalOrgId;

    constructor(private globalHelper: GlobalHelper, private viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        this.paymentPrint = this.options.paymentPrint;
        this.voucherPrint = this.options.voucherPrint;
        this.printLogo = this.globalHelper.getCurData('orgDocumentLogo');
        if (this.options.type === 'print' && this.options.patientDatas) {
            this.patientDatas = this.options.patientDatas;
        }
    }

    ngAfterViewInit () {
        const self = this;
        setTimeout(() => {
            if (self.options.type !== 'print') {
                self.getParentComponent().options.modalRef.close();
                self.getParentComponent()['closeModal']();
            }
            self.getParentComponent().paymodalPrint = false;
            if (self.printTemp) {
                const innerContents = self.printTemp.nativeElement.innerHTML,
                    popupWindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                popupWindow.document.open();
                popupWindow.document.write(`
					<html>
						<head>
							<link href="/assets/css/voucher_print.css" rel="stylesheet" type="text/css" />
							<style>

							</style>
						</head>
						<body onload="window.print()">
							${innerContents}
						</body>
					</html>`);
                popupWindow.document.close();
            }
        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

}
