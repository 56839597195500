import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freqValCond'
})
export class FreqValCondPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (`${value}` === '0') { return '-'; } else {
      const freqVal = value.split('.')[0];
      const freqDecimalVal = value.split('.')[1];
      if (freqVal && `${freqVal}` !== '0') {
        if (`${freqDecimalVal}` === '25') {
           return freqVal + ' <i>1/4</i>';
        } else if (`${freqDecimalVal}` === '75') {
            return freqVal + ' <i>3/4</i>';
        } else if (`${freqDecimalVal}` === '5') {
            return freqVal + ' <i>1/2</i>';
        }
      } else {
        if (`${freqDecimalVal}` === '25') {
            return '1/4';
        } else if (`${freqDecimalVal}` === '75') {
            return '3/4';
        } else if (`${freqDecimalVal}` === '5') {
            return '1/2';
        }
      }
    }
    return value;
  }

}
