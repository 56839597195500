import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loading',
  templateUrl: './skeleton-loading.component.html',
  styleUrls: ['./skeleton-loading.component.css']
})
export class SkeletonLoadingComponent implements OnInit {

    @Input('options') options: any;
    loadOptions: any;

    constructor() { }

    ngOnInit() {
        this.mergeOption();
    }

    mergeOption () {
        const defualtOptions = {
            count: '1',
            appearance: 'line',
            style: {
                height: '100%',
                'float': 'left',
                'margin': '2px 2px 2px 2px',
            },
            animation: 'progress'
        };
        this.loadOptions = {...defualtOptions, ...this.options};
    }

}
