import {OwlDateTimeFormats} from 'ng-pick-datetime';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'D-M-Y',
    fullPickerInput: 'D-M-Y h:mm A',
    datePickerInput: 'D-M-Y',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
