import { Injectable } from '@angular/core';

import { PatientService } from '../../../services/patient.service';

@Injectable()
export class PatientRequestFavouritesService extends PatientService {

    public modelPath = 'lab/pat_request_favourites';

}
