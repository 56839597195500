import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, Event } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoggerService {

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      this.logEvent(event);
    });
  }

  private logEvent(event: Event): void {
   if (event instanceof NavigationError) {
      console.log(`local Navigation to ${event.url} failed with error: `, event.error.message);
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      const chunkFailedMessage1 = /Loading chunk ([^\s]+) failed/;
      if (chunkFailedMessage.test(event.error.message) || chunkFailedMessage1.test(event.error.message)) {
        const {url} = {...event};
          console.log('reloaded --> ', url);
          window.location.replace(url);
      } else {
        console.error(event.error);
      }
    }
  }
}
