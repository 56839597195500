import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BaseService } from 'src/app/services/base/base.service';
import { GlobalHelper } from 'src/app/helper/global.helper';
import { UserService } from 'src/app/modules/configuration/users/services/user.service';

interface UrlObject {
    title: string;
    url: string;
}

// Maps the parameters from route to the given route-urls, creating a valid url
function mapParamsToRoute(
    urls: UrlObject[],
    params: Record<string, string>
): UrlObject[] {
    try {
        return urls.map((handle: UrlObject): UrlObject => {
            const { url, ...rest } = handle;
            let res: RegExpExecArray | null;
            const results: string[] = [];

            // Isolate parameters of the type `:xyz` from route-url
            const getUrlParams = /:([a-z\-_\d]+)/ig;

            // Patient module will have multiple params in the route to handle that we have checking the route in while loop
            while ((res = getUrlParams.exec(url)) !== null) {
                // First match is the whole string including the `/` & ':' so we discard it.
                // We only care about the individual parameters captured by the regexp-group.
                results.push(res[1]);
            }

            if (!results.length) { return handle; }

            // For each captured parameter, we check if a value for it exists in the params
            // And replace it in place if it exists.
            const _url = results.reduce((pUrl: string, key: string): string => {
                if (params[key]) {
                    return pUrl.replace(`:${key}`, params[key]);
                }
                return pUrl;
            }, url);

            return { ...rest, url: _url };
        });
    } catch (e) {
        // Fallback in case of error
        console.error('Error occurred while mapping parameters to route', e);
        return urls;
    }
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
    providers: [BaseService, UserService]
})
export class BreadcrumbComponent implements OnDestroy {

    @Input() layout;
    pageInfo;
    routerRef;
    currUserPackage: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private modelService: BaseService, private globalHelper: GlobalHelper,
        private userService: UserService) {
        const self = this;
        this.routerRef = this.router.events.pipe(filter(event =>
            event instanceof NavigationEnd)).pipe(map(() =>
            self.activatedRoute)).pipe(map(route => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }))
            .pipe(filter(route => route.outlet === 'primary'))
            .pipe(switchMap(route => combineLatest([route.data, route.params])))
            .subscribe(([event, params]) => {
                const urls = mapParamsToRoute(event.urls ?  event.urls : [], params);
                const disabledRoutes = ['/pharmacy/dashboard', '/configuration/organization'];
                urls.map((url: any) => url.disable = disabledRoutes.includes(url.url));
                // console.log(event);
                self.pageInfo = {...event, urls};
                self.modelService.baseHotKeysSet(self.pageInfo);

                if (self.pageInfo && self.pageInfo.buttonDatas && typeof self.pageInfo.buttonDatas === 'object') {
                    const userAccessUrls = self.globalHelper.getCurData('user-access-urls');
                    if (self.pageInfo.buttonDatas.redUrl === '/configuration/create-user') {
                        self.getCurrUserPackage();
                    }
                    if (!userAccessUrls.some(uAccData => uAccData.resource_name === self.pageInfo.buttonDatas.resrcName)) {
                        // tslint:disable-next-line:no-unused-expression
                        delete self.pageInfo.buttonDatas;
                    }
                }
        });
    }

    getCurrUserPackage () {
        const self = this;
        this.userService.getCurrUserPackage().subscribe((packageDatas: any) => {
            if (packageDatas) {
                self.pageInfo.buttonDatas.userPackageDetails = packageDatas;
                if (packageDatas.balance <= 0) {
                    self.pageInfo.buttonDatas.restrictVisible = true;
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.routerRef) { this.routerRef.unsubscribe(); }
        this.modelService.removehotKey();
    }
}
