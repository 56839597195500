import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayNameReplace'
})
export class DisplayNameReplacePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return value.replace(/[&,()%]/g, '');
    }

}
