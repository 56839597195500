export const PATIENT_MODULE_ROUTES: any = {
    defalutRoutes: ['change-appointment-status'],
    vitalRoutes: ['vitals', 'create-vital', 'update-vital'],
    documentRoutes: ['documents', 'update-case-history', 'view-case-history', 'create-case-history', 'create-scanned-document', 'create-other-document', 'create-medical-case-history', 'create-psychological-assessment', 'create-psychological-therapy'],
    noteRoutes: ['notes', 'view-note', 'create-note', 'update-note'],
    encounterRoutes: ['encounters', 'appointment', 'admission', 'discharge', 'transfer', 'swapping', 'modify-case-sheet-no', 'update-admission'],
    procedureRoutes: ['procedures', 'create-procedure', 'update-procedure'],
    consultantRoutes: ['consultant', 'create-consultant', 'update-consultant'],
    billingRoutes: ['billing', 'bill-details', 'add-payment', 'edit-payment', 'add-room-concession-amount', 'edit-other-charges', 'add-other-charges', 'add-extra-amount', 'edit-extra-amount', 'add-concession-amount', 'edit-concession-amount', 'billing-history', 'pharmacy-concession']
};
