import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewContainerRef, OnDestroy } from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit, OnDestroy {

    @ViewChild('modalTmpRef') modalTmpRef: NgbModalRef;
    @Input('options') options;
    @Output() modalSaveCB = new EventEmitter<string>();
    modalRef;

    constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        const self = this;
        setTimeout(() => {
            const option: NgbModalOptions  = {
                centered: true,
                backdrop: 'static'
            };
            self.options.modalRef = self.modalService.open(self.modalTmpRef, option);
            self.options.modalRef.result.then((result) => {}, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().showModal = false;
                }
            });

        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

    closeModal () {
        this.getParentComponent().dlProgress = 0;
        this.getParentComponent().showModal = this.getParentComponent().serviceCallStatus = false;
        this.modalSaveCB.next();
        this.options.modalRef.close();
    }

    ngOnDestroy () {
        this.options.modalRef.close();
        this.options = {};
    }

}
