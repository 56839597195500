import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[avoid-symbols]'
})
export class AvoidSymbolsDirective {

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event,
    reGX = /^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,-./]*$/;

    // Not allowed special charcter
    if (!reGX.test(e.key)) {
      e.preventDefault();
      return false;
    }
  }

}
