import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[uppercase]'
})
export class UppercaseDirective {

    constructor(private control: NgControl) {}

    @HostListener('blur', ['$event']) onEvent($event) {
        const upper = $event.target.value.toUpperCase();
        this.control.control.setValue(upper);
    }

}
