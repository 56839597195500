import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import {Store} from '@ngrx/store';

import { GlobalHelper } from '../../helper/global.helper';
import { PatientDetailFailure} from '../../modules/patient/store/Patient/patients.actions';
import { PatientModel } from '../../modules/patient/store/Patient/patients.model';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private globalHelper: GlobalHelper, private store: Store<{ data: PatientModel[] }>) {}

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (localStorage.getItem(this.globalHelper.getConstantsValue('app').currentUser)) {
            // For patient module we are storing the encounter type and consultant id in session storage, We are using this in next and previous patients api call
            if (state.url.includes('/patient/') && state.url.includes('?')) {
                this.globalHelper.lsPush(this.globalHelper.getConstantsValue('app').encounterType, JSON.stringify(route.queryParams));
                this.router.navigate([state.url.split('?')[0]]);
                return false;
            } else if (!state.url.includes('/patient/')) { // Removing the ngrx patient detail storage values if the route moves out of patient module
                this.store.dispatch(new PatientDetailFailure());
            }

            return true;
        }
        localStorage.setItem(this.globalHelper.getConstantsValue('app').lastSeenPage, location.pathname);
        this.router.navigate(['/login']);
        return false;
    }
}
