import { Component, Input } from '@angular/core';
import { PatientRequestService } from 'src/app/modules/patient/request-and-result/services';

import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
  selector: 'app-request-print-btn',
  templateUrl: './request-print-btn.component.html',
  styleUrls: ['./request-print-btn.component.css'],
  providers: [ PatientRequestService ]
})
export class RequestPrintBtnComponent {
  @Input('printContent') printContent;
  @Input('requestPrint') requestPrint;
  @Input('moduleId') moduleId;

  printDatas: any = {};
  showResultPrint = false;

  constructor(private globalHelper: GlobalHelper, private modelService: PatientRequestService) { }

  printReq (reqDatas) {
        reqDatas.reqPrintLoading = true;
        const self = this, curOrgId = this.globalHelper.getCurData('curOrgId'),
        ajHospitalOrgId = this.globalHelper.getConstantsValue('app').ajHospitalOrgId,
        paramsOptions = this.globalHelper.convertObjectToParams({ pat_request_id: reqDatas.pat_request_id, module_id: this.moduleId});
        this.modelService.printPatReqs({params: paramsOptions}).subscribe((results: any) => {
            if (results) {
                results['module_id'] = this.moduleId;
                self.printDatas = results;
                self.showResultPrint = true;
                const printSetting = self.printDatas.patient_request.patientResultPrintSetting;
                let appendStyle = `margin-bottom: ${printSetting.margin_bottom}mm;`;
                    appendStyle += `margin-top: ${printSetting.margin_top}mm;`;
                    appendStyle += `margin-left: ${printSetting.margin_left}mm;`;
                    appendStyle += `margin-right: ${printSetting.margin_right}mm`;
                setTimeout(() => {
                    (<any>$('#result-print')).print({
                        globalStyles: true,
                        mediaPrint: false,
                        stylesheet: '/assets/css/prescription_print.css',
                        noPrintSelector: '.no-print',
                        iframe: false,
                        append: `<style>@page{${curOrgId !== ajHospitalOrgId ? appendStyle : ''}}</style>`,
                        prepend: '',
                        title: 'Result Print'
                    });
                    reqDatas.reqPrintLoading = self.showResultPrint = false;
                }, 600);
            }
        }, error => {
            reqDatas.reqPrintLoading = false;
        });
    }

}
