export const LAB_MODULES = [{
    label: 'Laboratory',
    value: 1
}, {
    label: 'Pathology',
    value: 2
}, {
    label: 'Radiology',
    value: 3
}];
