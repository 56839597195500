import { Injectable } from '@angular/core';

import { PatientService } from '../../../services/patient.service';

@Injectable()
export class EncounterService extends PatientService {

    public modelPath = 'patient/encounters';

    getBodyDatas () {
        return {
            columns: [{
                data: 'encounter_id',
                name: 'encounter_id',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'date_time',
                name: 'date_time',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            },
            {
                data: 'encounter_type',
                name: 'encounter_type',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'doctor',
                name: 'doctor',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 0,
                    dir: 'desc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getPatientEncounters (paramDatas) {
        // return this.httpService.postMethod('patient/patients/get_patient_byguid', paramDatas);
        const patientDatas = this.httpService.getMethod('dummy/encounter');
        // this.setPatientDatas(patientDatas);
        return patientDatas;
    }

    addAppointment (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/create_appointment`, bodyDatas);
    }

    addAdmission (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/create_admission`, bodyDatas);
    }

    getCurrentAdmissionRoom () {
        return this.httpService.getMethod(`${this.modelPath}/get_current_admission_room`);
    }

    changePatientCaseSheetNo (bodyDatas) {
        return this.httpService.postMethod('patient/case_sheets', bodyDatas);
    }

    getApptSeenEncounter (bodyDatas) {
        return this.httpService.postMethod('patient/encounters/appointment_seen_encounter', bodyDatas);
    }

    getActiveEncounter (patientGuid) {
        return this.httpService.getMethod(`${this.modelPath}/get_active_encounter?patient_guid=` + patientGuid);
    }

    getPatientActiveStatus (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/patient_have_encounter`, optionParams);
    }

    printHeader() {
        return {};
    }

    printFooter() {
        return {};
    }

    printStyle() {
        return {
            h1: {
                fontSize: 11,
                bold: true,
            },
            h2: {
                fontSize: 9,
                bold: true,
            },
            th: {
                fontSize: 9,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 8,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 9,
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 3, 5, 3]
            },
            header: {
                fontSize: 20,
                bold: true,
            },
            subheader: {
                fontSize: 15,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
        };
    }

    async getPMContent(reportList, branchName, generatedBy, totalValues) {
        const { medclinicOrgId, ajHospitalOrgId } = this.globalHelper.getConstantsValue('app'),
            curOrgId = this.globalHelper.getCurData('curOrgId');
        let print_setting;
        print_setting = reportList.printSetting;
        // Image Loading Start
        const img: any = curOrgId === medclinicOrgId ? document.getElementById('medclinic_logo') : document.getElementById('ahana_logo');
        const canvas = document.createElement('canvas');
        canvas.width = img['width'];
        canvas.height = img['height'];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');

        const title = print_setting.title ? print_setting.title_text : '';
        const auth_sign = print_setting.authorized_sign ? 'Authorized Signatory' : '';
        const branch_details = print_setting.org_name ? reportList.professionalDetails.encounter_tenant : '';

        let logo;
        if (print_setting.logo === 1) {
            logo = [{
                image: 'data:image/jpeg;base64,' + print_setting.logoUrl,
                height: curOrgId === ajHospitalOrgId ? 60 : 20,
                width: curOrgId === ajHospitalOrgId ? 90 : 70,
            }];
        } else {
            logo = [{
                text: ''
            }];
        }

        const content = [];
        const perPageInfo = [];
        const perPageItems = [];
        let header = [];
        perPageItems.push([
            {
                text: 'S.No',
                style: 'th'
            },
            {
                text: 'Service',
                style: 'th'
            },
            {
                text: 'Description',
                style: 'th'
            },
            {
                text: 'Amount',
                style: 'th'
            },
        ]);
        for (let i = 0; i < reportList.procedureDetails.length; i++) {
            let charges;
            const row = reportList.procedureDetails[i];
            if (row.charges) {
                charges = row.charges;
            } else {
                charges = 'Procedure Charges';
            }
            perPageItems.push([
                {
                    text: i + 1,
                    style: 'th'
                },
                {
                    text: charges,
                    style: 'th'
                },
                {
                    text: row.procedure_name,
                    style: 'th'
                },
                {
                    text: row.charge_amount,
                    style: 'th'
                },
            ]);
        }
        perPageItems.push([{
            colSpan: 4,
            text: 'Bill Total : ' + reportList.opPaidAmount.toFixed(2),
            alignment: 'right'
        }, {}, {}, {}], [{
            colSpan: 4,
            text: 'Amount Paid : ' + reportList.opPaidAmount.toFixed(2),
            alignment: 'right'
        }, {}, {}, {}]);
        if (curOrgId === ajHospitalOrgId) {
            header = [
                {
                    colSpan: 3,
                    layout: 'noBorders',
                    table: {
                        body: [
                            logo
                        ]
                    },
                }, {}, {},
                {
                    colSpan: 3,
                    layout: 'noBorders',
                    table: {
                        body: [
                            [
                                {	margin: [0, 12, 0, 0],
                                    text: [
                                        {text: 'A. J. HOSPITAL\n', style: 'header'},
                                        {text: '(A Unit of GRAHAM CLINIC)\n', style: 'subheader'},
                                        {text: title, style: 'h2'},
                                    ],
                                    alignment: 'center'
                                },
                            ],
                        ]
                    },
                },
                {}, {},
                {
                    layout: 'noBorders',
                    table: {
                        body: [
                            [
                                {
                                    margin: [0, 0, 0, 0],
                                    text: '',
                                    fontSize: 8,
                                    alignment: 'right'
                                },
                            ],
                        ]
                    },
                }
            ];
        } else {
            header = [
                {
                    colSpan: 3,
                    layout: 'noBorders',
                    table: {
                        body: [
                            logo
                        ]
                    },
                }, {}, {},
                {
                    colSpan: 3,
                    layout: 'noBorders',
                    table: {
                        body: [
                            [
                                {
                                    text: title,
                                    style: 'h2'
                                },
                            ],
                        ]
                    },
                },
                {}, {},
                {
                    layout: 'noBorders',
                    table: {
                        body: [
                            [
                                {
                                    margin: [0, 0, 0, 0],
                                    text: branch_details,
                                    fontSize: 8,
                                    alignment: 'right'
                                },
                            ],
                        ]
                    },
                }
            ];
        }

        perPageInfo.push(
            {
                layout: 'noBorders',
                table: {
                    widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', curOrgId === ajHospitalOrgId ? 100 : 'auto'],
                    body: [
                        header,
                    ]
                },
            });
            const patDatas: any = {};
            if (reportList.professionalDetails && reportList.professionalDetails.all_tenant_app_seen) {
                patDatas.name = reportList.professionalDetails.all_tenant_app_seen.patientName;
                patDatas.uhid = reportList.professionalDetails.all_tenant_app_seen.patientUhid;
                patDatas.age = reportList.professionalDetails.all_tenant_app_seen.patient_age ? reportList.professionalDetails.all_tenant_app_seen.patient_age.age : '';
                patDatas.gender = reportList.professionalDetails.all_tenant_app_seen.patient_gender;
                patDatas.paymentType = reportList.professionalDetails.all_tenant_app_seen.payment_mode ? this.globalHelper.getPaymentTxt(reportList.professionalDetails.all_tenant_app_seen.payment_mode) : '';
            }
            perPageInfo.push({
                layout: 'Borders',
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            {
                                border: [false, true, false, false],
                                colSpan: 6,
                                layout: {
                                    paddingLeft: function (i, node) {
                                        return 0;
                                    },
                                    paddingRight: function (i, node) {
                                        return 2;
                                    },
                                    paddingTop: function (i, node) {
                                        return 0;
                                    },
                                    paddingBottom: function (i, node) {
                                        return 0;
                                    },
                                },
                                table: {
                                    body: [
                                        [
                                            {
                                                border: [false, false, false, false],
                                                text: 'Patient Name',
                                                style: 'h2',
                                                margin: [-5, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            },
                                            {
                                                border: [false, false, false, false],
                                                text: patDatas ? patDatas.name : '-',
                                                style: 'normaltxt'
                                            }
                                        ],
                                        [
                                            {
                                                border: [false, false, false, false],
                                                text: 'UHID',
                                                style: 'h2',
                                                margin: [-5, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            },
                                            {
                                                border: [false, false, false, false],
                                                text: patDatas ? patDatas.uhid : '-',
                                                style: 'normaltxt'
                                            }
                                        ],
                                        [
                                            {
                                                border: [false, false, false, false],
                                                text: 'Age / Sex',
                                                style: 'h2',
                                                margin: [-5, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            },
                                            {
                                                border: [false, false, false, false],
                                                text: `${patDatas && patDatas.age ? patDatas.age + ' Y' : '-'} / ${patDatas ? patDatas.gender : '-'}`,
                                                style: 'normaltxt'
                                            }
                                        ],
                                    ]
                                },
                            },
                            {}, {}, {}, {}, {},
                            {
                                border: [false, true, false, false],
                                layout: 'noBorders',
                                table: {
                                    body: [
                                        [
                                            {
                                                border: [false, false, false, false],
                                                text: 'Bill No',
                                                style: 'h2',
                                                margin: [-7, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            },
                                            {
                                                border: [false, false, false, false],
                                                text: reportList.professionalDetails.bill_no,
                                                style: 'normaltxt'
                                            }
                                        ],
                                        [
                                            {
                                                text: 'Bill Date',
                                                style: 'h2',
                                                margin: [-7, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                style: 'h2'
                                            },
                                            {
                                                text: this.globalHelper.getMomentDatas('DD/MM/YYYY - hh:mm A', reportList.professionalDetails.encounter_date),
                                                style: 'normaltxt'
                                            }
                                        ],
                                        curOrgId === ajHospitalOrgId ? [
                                            {
                                                text: 'Billed By',
                                                style: 'h2',
                                                margin: [-7, 0, 0, 0],
                                            },
                                            {
                                                text: ':',
                                                style: 'h2'
                                            },
                                            {
                                                text: this.globalHelper.getCurData('curUserName'),
                                                style: 'normaltxt'
                                            }
                                        ] : [{}, {}, {}]
                                    ]
                                },
                            }
                        ],
                    ]
                },
            }, {
                table: {
                    widths: ['auto', 150, '*', 'auto'],
                    body: perPageItems,
                },
                layout: {
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
                    },
                }
            });
            perPageInfo.push({
                style: 'tableExample',
                layout: 'noBorders',
                table: {
                    widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            {
                                colSpan: 6,
                                layout: 'noBorders',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'Payment Mode',
                                                style: 'h2'
                                            },
                                            {
                                                text: ':',
                                                style: 'h2'
                                            },
                                            {
                                                text: `${patDatas && patDatas.paymentType ? patDatas.paymentType : '-'} (${reportList.opPaidAmount.toFixed(2)})`,
                                                style: 'normaltxt'
                                            },
                                        ],
                                        [
                                            {
                                                colSpan: 3,
                                                text: [
                                                    {text: this.globalHelper.toTitleCase(this.globalHelper.convertNumberToWord(parseInt(reportList.opPaidAmount))) + ' Rupees Only'},
                                                ]
                                            },
                                            {}, {},
                                        ],
                                    ]
                                },
                            }, {}, {}, {}, {}, {},
                            {
                                layout: 'noBorders',
                                table: {
                                    body: [
                                        [
                                            {
                                                text: 'For ' + reportList.professionalDetails.encounter_tenant,
                                                style: 'h2'
                                            },
                                            {
                                                text: '',
                                                style: 'h2'
                                            },
                                            {
                                                text: '',
                                                style: 'normaltxt'
                                            },
                                        ],
                                        [
                                            {
                                                colSpan: 3,
                                                text: auth_sign,
                                                style: 'h2',
                                                margin: [0, 15, 0, 0],
                                            },
                                            {}, {},
                                        ],
                                    ]
                                },
                            }
                        ],
                    ]
                },
            });
        content.push(perPageInfo);
        return content;
    }

}
