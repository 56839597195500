import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

import { GlobalHelper } from '../../helper/global.helper';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private router: Router, private location: Location, private globalHelper: GlobalHelper) {}

    canActivate() {
        if (localStorage.getItem(this.globalHelper.getConstantsValue('app').currentUser)) {
            if (window.history.length > 2) { this.location.back(); } else { this.router.navigate(['my-works/dashboard']); }
            return false;
        }
        return true;
    }
}
