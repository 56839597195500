import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[freq-dpdn-validate]'
})
export class FreqDpdnValidateDirective {

    constructor(private elementRef: ElementRef, private control: NgControl) {}

    // @HostListener('blur',['$event']) onBlur($event) {
    // 	if ($event.target.value==' ') this.control.control.setValue(0);
    // }

    // @HostListener('focus',['$event']) onFocus($event) {
    // 	if ($event.target.value==0) this.control.control.setValue('');
    // }

    @HostListener('blur', ['$event']) onBlur($event) {
        if ($event.target.value === '') { this.control.control.setValue(0); }
    }

    @HostListener('focus', ['$event']) onFocus($event) {
        if ($event.target.value === 0) { this.control.control.setValue(null); }
        // this.elementRef.nativeElement.select();
    }

}
