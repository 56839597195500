import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { SaleReturnsService } from '../../modules/pharmacy/sale-returns/services/sale-returns.service';
import { GlobalHelper } from '../../helper/global.helper';

@Component({
    selector: 'app-common-sale-return-form-elements',
    templateUrl: './common-sale-return-form-elements.component.html',
    styleUrls: ['./common-sale-return-form-elements.component.css']
})
export class CommonSaleReturnFormElementsComponent implements OnInit, AfterViewInit {

    submitSub: Subscription;
    @Input('modelData') modelData;
    @Input('pageTitle') pageTitle;
    @Input('formRef') formRef;
    tempFinalAmt: any = 0;
    overAllDisType = '';
    accessMaxDate = this.globalHelper.getCurDateObjWithTime('max');
    saveAndPrint = false;
    formLoading = false;
    customFormCond = false;
    zeroQtyStatus = true;
    billModel: any = {};
    err: any = {};
    allProducts = [];
    allHSNCodes = [];
    paymentTypes = this.globalHelper.getCurData('payType');

    searchingBill: any = (text$: Observable < string > ) => text$.pipe(debounceTime(300),
        distinctUntilChanged(), switchMap(term =>
            this.modelService.billSearch(term).pipe(map((response) => {
                return response;
            }), catchError(() => {
                return of([]);
            }))
        ))
    billInFormatter = (x: { bill_no_with_patient: string }) => x.bill_no_with_patient;
    billOutFormatter = (x: { bill_no_with_patient: string }) => x.bill_no_with_patient;
    searchingProducts = (text$: Observable < string > ) => text$.pipe(debounceTime(200), map(term => term === '' ? [] :
        this.allProducts.filter(v => v.full_name && v.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
    productInFormatter = (x: {full_name: string}) => x.full_name;
    productOutFormatter = (x: {full_name: string}) => x.full_name;

    constructor(public router: Router, private modelService: SaleReturnsService,
        private globalHelper: GlobalHelper) {}

    ngOnInit () {
        const self = this;
        this.modelData.product_items.map((item) => {
            self.itemChanges(item);
        });
    }

    ngAfterViewInit () {
        this.inputChanges();
    }

    inputChanges () {
        $('input').on('focus', function (ev) {
            $(this).select();
        });
        setTimeout(() => {
            $('input[type=number]').on('mousewheel', function(e) {
                $(this).blur();
            });
        }, 500);
    }

    checkReturnQuantity (item) {
        if (this.pageTitle === 'Update') {
            const pQty = parseFloat(item.quantity),
            pOldQty = parseFloat(item.old_quantity);
            this.customFormCond = item.noStock = item.qtyExceed = false;
            if (pOldQty < pQty) {
                this.checkQuantity(item);
            } else {
                const curQty = (pOldQty - pQty); // *parseFloat(item.package_unit);
                if (curQty > parseFloat(item.batch.available_qty)) {
                    this.customFormCond = item.noStock = true;
                }
            }
        } else {
            this.checkQuantity(item);
        }
    }

    checkQuantity (item) {
        const total = parseFloat(item.quantity) + parseFloat(item.total_returned_quantity);
        this.customFormCond = item.qtyExceed = total > item.sale_quantity;
    }

    itemChanges (item) {
        this.checkReturnQuantity(item);
        if (item.quantity || `${item.quantity}` === '0') {
            item['taxable_value'] = (((parseFloat(item.mrp) / (100 + parseFloat(item.cgst_percent) + parseFloat(item.sgst_percent)) * 100)) * parseFloat(item.quantity)).toFixed(2);
            if (item.discount_percentage) {
                item.discount_amount = (((item.discount_percentage / 100) * parseFloat(item.taxable_value))).toFixed(2);
            }
            if (item.discount_amount) {
                item.taxable_value = (parseFloat(item.taxable_value) - parseFloat(item.discount_amount)).toFixed(2);
            }
            item.cgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.cgst_percent)) / 100).toFixed(2));
            item.sgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.sgst_percent)) / 100).toFixed(2));
            item.total_amount = (parseFloat(item.taxable_value) + parseFloat(item.sgst_amount) + parseFloat(item.cgst_amount)).toFixed(2);
            this.getTotalReturnAmt();
        }
        this.zeroQtyStatus = !(this.modelData.product_items.some(prodItem => prodItem.quantity && (`${prodItem.quantity}` !== '0' || prodItem.quantity !== null) && prodItem.quantity > 0 && !prodItem.qtyExceed && !prodItem.noStock));
    }

    getTotalReturnAmt () {
        this.modelData.total_item_vat_amount = 0;
        this.modelData.total_item_sale_amount = 0;
        const productItems = this.modelData.product_items;
        for (let i = 0; i < productItems.length; i++) {
            this.modelData.total_item_vat_amount = (parseFloat(this.modelData.total_item_vat_amount) + parseFloat(productItems[i].cgst_amount) + parseFloat(productItems[i].sgst_amount)).toFixed(2);
            this.modelData.total_item_sale_amount = (parseFloat(this.modelData.total_item_sale_amount) + parseFloat(productItems[i].total_amount)).toFixed(2);
            this.tempFinalAmt = this.tempFinalAmt + parseFloat(productItems[i].total_amount);
            if (i === productItems.length - 1) {
                if (this.modelData.total_item_discount_percent) {
                    this.finalDiscount();
                } else {
                    this.modelData.total_item_amount = this.modelData.total_item_sale_amount;
                    this.modelData.bill_amount = Math.round(parseFloat(this.modelData.total_item_amount)).toFixed(2);
                    this.modelData.roundoff_amount = (parseFloat(this.modelData.bill_amount) - parseFloat(this.modelData.total_item_amount)).toFixed(2);
                }
            }
        }
    }

    finalDiscount () {
        this.modelData.total_item_discount_amount = ((parseFloat(this.modelData.total_item_sale_amount) * this.modelData.total_item_discount_percent) / 100).toFixed(2);
        this.modelData.total_item_amount = (parseFloat(this.modelData.total_item_sale_amount) - parseFloat(this.modelData.total_item_discount_amount)).toFixed(2);
        this.modelData.bill_amount = Math.round(parseFloat(this.modelData.total_item_amount)).toFixed(2);
        this.modelData.roundoff_amount = (parseFloat(this.modelData.bill_amount) - parseFloat(this.modelData.total_item_amount)).toFixed(2);
    }

    changeAllowNewSale (oNSStatus, formRef) {
        if (oNSStatus) {
            this.modelData.type = 'return';
            this.modelData['sale_items'] = [{selectBatch: '', quantity: 0, hsn_no: '', discount_percentage: 0, discount_amount: 0}];
            this.modelData.saleModel =  Object.assign({}, ...this.modelData);
        }
        this.changePayType(formRef);
    }

    changePayType (formRef) {
        if (formRef.submitted) {
            formRef.submitted = false;
            setTimeout(() => {
                formRef.submitted = true;
            });
        }
    }

    changePaymentType (formRef) {
        this.modelData.allowNewSale = false;
        this.modelData.payment_mode = this.modelData.sale_payment_type === 'CA' ? 'CA' : '';
        const self = this;
        setTimeout(() => {
            self.modelData.allowNewSale = true;
        });
        this.changePayType(formRef);
    }

}
