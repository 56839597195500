import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(collection: Array<object>, property: string): Array<object> {
        if (!collection) {  return null; }
        return collection.sort((a, b) => a[property] > b[property] ? 1 : a[property] === b[property] ? 0 : -1);
    }

}
