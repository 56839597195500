import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ttpHovBodyScrollToggle]'
})
export class BodyScrollToggleDirective {

  constructor() { }

  @HostListener('mouseenter') onMouseEnter () {
    $('body').css('overflow-y', 'hidden');
  }

  @HostListener('mouseleave') onMouseLeave () {
    $('body').css('overflow-y', 'scroll');
  }

}
