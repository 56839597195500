import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-loading',
  templateUrl: './site-loading.component.html',
  styleUrls: ['./site-loading.component.css']
})
export class SiteLoadingComponent implements OnInit {

  @Input('content')content;

  constructor() { }

  ngOnInit() {
  }

}
