import { Injectable } from '@angular/core';

import { PatientService } from '../../services/patient.service';

@Injectable()
export class NoteService extends PatientService {

    public modelPath = 'patient/notes';

    getBodyDatas () {
        return {
            columns: [{
                data: 'pat_note_id',
                name: 'pat_note_id',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'encounter_id',
                name: 'encounter_id',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 0,
                    dir: 'asc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getNotesUnseenCount (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_notes_count_by_patient`, optionParams);
    }

    setNoteSeen (optionParams) {
        return this.httpService.postMethod(`${this.modelPath}/seen_notes`, optionParams);
    }

}
