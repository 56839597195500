import { Injectable } from '@angular/core';

import { BaseService } from '../../../services/base/base.service';

@Injectable()
export class PharmacyService extends BaseService {
    public emailExportPath = '';

    getReportDatatableByEmail(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.emailExportPath}`, bodyDatas || {}, options);
    }

    getPrintHeader ({ showlogo, logo, gst_no, dl_nos, header, bar_img, saleHeader }) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app');
        if (orgId === ajHospitalOrgId) {
            return [[{
                colSpan: 2,
                text: gst_no,
                fontSize: 7
            }, {}, {
                colSpan: 4,
                text: showlogo ? '21, P.S.R. Road, SIVAKASI - 626123' : '',
                fontSize: 10,
                italics: false
            }, {}, {}, {}, {
                text: dl_nos,
                fontSize: 7
            }]];
        }
        return [[{
            colSpan: 3,
            layout: 'noBorders',
            table: {
                body: [
                    logo,
                    [{
                        text: saleHeader,
                        fontSize: 9,
                        style: 'h2',
                        margin: [0, -5, 0, 0]
                    }]
                ]
            },
        }, {}, {}, {
            colSpan: 3,
            layout: 'noBorders',
            margin: [0, 7, 0, 0],
            table: {
                body: [
                    [{
                        text: 'Cash on Delivery : 7338877882',
                        fontSize: 8,
                        style: 'h2'
                    }], [{
                        text: `${gst_no}       ${dl_nos}`,
                        fontSize: 8
                    }]
                ]
            },
        }, {}, {}, {
            layout: 'noBorders',
            margin: [0, 6, 0, 0],
            table: {
                body: [
                    [{
                        text: header,
                        fontSize: 8,
                        margin: [0, -5, 0, -5],
                        alignment: 'center'
                    }],
                    bar_img
                ]
            }
        }]];
    }

    getSalePrintContent (printDatas, fromSale, isSaleReturn?: any) {
        const content = [], sale = printDatas.saleReturnDatas ? printDatas.saleDatas : printDatas,
            orgId = this.globalHelper.getCurData('curOrgId'),
            saleReturnData = printDatas.saleReturnDatas && fromSale,
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app'),
            print_setting = sale.salePrintSetting ? sale.salePrintSetting : {},
            result_count = Object.keys(sale.items).length,
            groupedArr = this.createGroupedArray(sale.items, 10);
        let index = 1, loop_count = 0, cgst_total = 0, sgst_total = 0, sale_discount,
            groupName; // Changed Description rows
        if (sale['patient_group_name']) {
            groupName = {
                layout: 'Borders',
                text: ['Group Name : ', { text: sale['patient_group_name'], bold: false }],
                bold: true,
                style: 'normaltxt',
                margin: [0, -5, 0, 0],
            };
        } else {
            groupName = {};
        }
        if (sale['total_item_discount_amount'] !== '0.00') {
            sale_discount = [
                {
                    text: 'Discount',
                    style: 'h2',
                    alignment: 'right'
                },
                {
                    text: ':',
                    style: 'h2'
                },
                {
                    text: sale['total_item_discount_amount'],
                    alignment: 'right'
                }
            ];
        } else {
            sale_discount = [{
                text: ''
            }, {
                text: ''
            }, {
                text: ''
            }];
        }
        const group_total_count = Object.keys(groupedArr).length;
        for (let i = 0; i < groupedArr.length; i++) {
            const group_key = i + 1;

            const perPageInfo = [];

            const perPageItems = [];

            perPageItems.push([{
                border: [false, true, false, false],
                rowspan: 2,
                text: 'S.No',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Product',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Batch',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Expiry',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Qty',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Price',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Taxable',
                style: 'th'
            }, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Discount',
                style: 'th'
            }, {
                border: [false, true, false, true],
                colSpan: 2,
                alignmanet: 'center',
                text: 'CGST',
                style: 'th'
            }, {}, {
                border: [false, true, false, true],
                colSpan: 2,
                alignmanet: 'center',
                text: 'SGST',
                style: 'th'
            }, {}, {
                border: [false, true, false, false],
                rowspan: 2,
                text: 'Total',
                style: 'th'
            }], [{
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                text: ''
            }, {
                border: [false, false, false, true],
                margin: [0, -5, 0, -5],
                text: 'value'
            }, {
                border: [false, false, false, true],
                margin: [0, -5, 0, -5],
                text: '%'
            }, {
                border: [false, true, false, true],
                text: '%',
                fontSize: 7
            }, {
                border: [false, true, false, true],
                text: 'Amount',
                fontSize: 7
            }, {
                border: [false, true, false, true],
                text: '%',
                fontSize: 7
            }, {
                border: [false, false, false, true],
                text: 'Amount',
                fontSize: 7
            }, {
                border: [false, false, false, true],
                text: ''
            }]);

            for (let j = 0; j < groupedArr[i].length; j++) {
                // let particulars;
                // let percentage = parseInt(groupedArr[i][j].discount_percentage);
                // if (percentage > 0) {
                //     particulars = groupedArr[i][j]['prod']['product_fullname'] + '(' + percentage.toString() + ')';
                // } else {
                //     particulars = groupedArr[i][j]['prod']['product_fullname'];
                // }
                cgst_total += parseFloat(groupedArr[i][j].cgst_amount);
                sgst_total += parseFloat(groupedArr[i][j].sgst_amount);
                let color, border;
                if (loop_count % 2 === 0) {
                    color = '';
                } else {
                    color = '#eeeeee';
                }
                if (result_count === loop_count + 1) {
                    border = [false, false, false, true];
                } else {
                    border = [false, false, false, false];
                }
                perPageItems.push([{
                    border: border,
                    text: index,
                    fillColor: color,
                    style: 'td',
                    alignment: 'left'
                }, {
                    border: border,
                    text: groupedArr[i][j]['prod']['product_fullname'],
                    fillColor: color,
                    style: ['td']
                }, {
                    border: border,
                    text: groupedArr[i][j].batch.batch_no,
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: groupedArr[i][j].batch.expiry_date,
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: groupedArr[i][j].quantity,
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: groupedArr[i][j].mrp,
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].taxable_value || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].discount_amount || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].cgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].cgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].sgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].sgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: groupedArr[i][j].total_amount,
                    fillColor: color,
                    style: 'td',
                    alignment: 'right'
                }]);
                index++;
                loop_count++;
            }
            let payment;
            if (sale['payment_type'] === 'CA') {
                payment = 'Cash';
            }
            if (sale['payment_type'] === 'CR') {
                payment = 'Credit';
            }
            if (sale['payment_type'] === 'COD') {
                payment = 'Cash On Delivery';
            }
            let bar_image, bar_img;
            const barCodeElemt = document.getElementById(sale['Uhid']);
            if (sale['Uhid'] && document.contains(barCodeElemt)) {
                bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
            }
            if (bar_image) { // Check Bar image is empty or not
                bar_img = [{
                    image: bar_image,
                    height: 20,
                    width: 100
                }];
                if (orgId === ajHospitalOrgId) { bar_img[0] = { ...bar_img[0], ...{ alignment: 'right', margin: [0, -20, 0, 0] } }; }
            } else {
                bar_img = [{
                    text: ''
                }];
            }
            // Image Loading End
            let logo;
            if (print_setting.logo === 1) {
                logo = [{
                    columns: [{
                        image: 'data:image/jpeg;base64,' + print_setting.logoUrl,
                        height: 20,
                        width: 100,
                        margin: isSaleReturn ? [0, 8, 0, -3] : '',
                        }, {
                            text: `(${sale['branch_address']})`,
                            margin: isSaleReturn ? [0, 15, 0, 0] : [0, 5, 0, 0],
                            fontSize: 8
                        }
                    ]
                }];
            } else {
                logo = [{
                    text: ''
                }];
            }

            const header = print_setting.title ? print_setting.title_text : '';
            const dl_nos = print_setting.dl_nos ? `${orgId === ajHospitalOrgId ? 'DL.NO.' : 'DL Nos. '}: ` + print_setting.dl_no_text : '';
            const gst_no = print_setting.gst_no ? `${orgId === ajHospitalOrgId ? 'GSTIN' : 'GST No '}: ` + print_setting.gst_no_text : '';

            if (orgId === ajHospitalOrgId) {
                perPageInfo.push({ text: print_setting.logo === 1 ? 'ARUN KUMAR MEDICALS' : '', fontSize: 14, bold: true, alignment: 'center' });
                perPageInfo.push({ text: print_setting.logo === 1 ? '(A Unit of GRAHAM CLINIC)' : '', fontSize: 12, alignment: 'center', italics: false });
                perPageInfo.push(bar_img);
            }

            perPageInfo.push({
                layout: 'noBorders',
                table: {
                    widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                    body: this.getPrintHeader({ showlogo: print_setting.logo === 1, logo, gst_no, dl_nos, header: isSaleReturn ? header : '', bar_img, saleHeader : isSaleReturn ? '' : header })
                }
            });
            if (loop_count === result_count) {
                perPageItems.push([{
                    border: [false, false, false, true],
                    colSpan: 8,
                    text: 'Total :',
                    alignment: 'right',
                    style: ['td', 'h2']
                }, {}, {}, {}, {}, {}, {}, {}, {
                    border: [false, false, false, true],
                    text: cgst_total.toFixed(2),
                    style: ['td']
                }, {
                    border: [false, false, false, true],
                    text: cgst_total.toFixed(2),
                    style: ['td']
                }, {
                    border: [false, false, false, true],
                    text: sgst_total.toFixed(2),
                    style: ['td']
                }, {
                    border: [false, false, false, true],
                    text: sgst_total.toFixed(2),
                    style: ['td']
                }, {
                    border: [false, false, false, true],
                    text: sale['total_item_sale_amount'],
                    style: ['td']
                }]);
            }
            const { patientTitle, patientName } = this.globalHelper.getPatientTitleAndName(sale['patient_name']);
            perPageInfo.push({
                layout: 'Borders',
                table: {
                    widths: ['auto', '*'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            layout: {
                                paddingLeft: function (idx, node) {
                                    return 0;
                                },
                                paddingRight: function (idx, node) {
                                    return 2;
                                },
                                paddingTop: function (idx, node) {
                                    return 0;
                                },
                                paddingBottom: function (idx, node) {
                                    return 0;
                                },
                            },
                            table: {
                                widths: ['auto', 5, 'auto'],
                                body: [
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Date',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    }, {
                                        border: [false, false, false, false],
                                        text: ':',
                                        style: 'h2'
                                    }, {
                                        border: [false, false, false, false],
                                        text: this.globalHelper.getMomentDatas('app-date-time', sale['sale_date']),
                                        style: 'normaltxt'
                                    }],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Patient',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: `${patientTitle}${patientName}`,
                                        style: 'normaltxt'
                                    }
                                    ],
                                ]
                            },
                        }, {
                            border: [false, true, false, false],
                            layout: {
                                paddingLeft: function (idx, node) {
                                    return 0;
                                },
                                paddingRight: function (idx, node) {
                                    return 2;
                                },
                                paddingTop: function (idx, node) {
                                    return 0;
                                },
                                paddingBottom: function (idx, node) {
                                    return 0;
                                },
                            },
                            table: {
                                widths: ['auto', 5, 70, 25, 5, 100, 25, 5, '*'],
                                body: [[{
                                    border: [false, false, false, false],
                                    text: 'UHID',
                                    style: 'h2',
                                    margin: [-5, 0, 0, 0]
                                }, {
                                    text: ':',
                                    border: [false, false, false, false],
                                    style: 'h2'
                                }, {
                                    border: [false, false, false, false],
                                    text: sale['Uhid'] || '-',
                                    style: 'normaltxt'
                                }, {
                                    border: [false, false, false, false],
                                    text: 'Bill No',
                                    style: 'h2',
                                    margin: [-5, 0, 0, 0]
                                }, {
                                    text: ':',
                                    border: [false, false, false, false],
                                    style: 'h2'
                                }, {
                                    border: [false, false, false, false],
                                    text: `${sale['bill_no']}/${payment}`,
                                    style: 'normaltxt'
                                }, {
                                    border: [false, false, false, false],
                                    text: 'Doctor',
                                    style: 'h2',
                                    margin: [-5, 0, 0, 0]
                                }, {
                                    text: ':',
                                    border: [false, false, false, false],
                                    style: 'h2'
                                }, {
                                    border: [false, false, false, false],
                                    text: sale['consultant_name'] || '-',
                                    style: 'normaltxt'
                                }
                                ], [{
                                    border: [false, false, false, false],
                                    text: 'Address',
                                    style: 'h2',
                                    margin: [-5, 0, 0, 0]
                                },
                                {
                                    text: ':',
                                    border: [false, false, false, false],
                                    style: 'h2'
                                },
                                {
                                    border: [false, false, false, false],
                                    colSpan: 7,
                                    text: this.globalHelper.toTitleCase((sale.patient_address && sale.patient_address.addr_perm_address) ? sale.patient_address.addr_perm_address.replace(/\n/ig, ' ') : ' - '),
                                    style: 'normaltxt'
                                }]]
                            }
                        }],
                    ]
                },
            }, groupName, {
                layout: {
                    hLineWidth: function (idx, node) {
                        return (idx === 0) ? 3 : 1;
                    }
                },
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: perPageItems,
                }
            });

            perPageInfo.push(saleReturnData ? {} : {
                layout: 'noBorders',
                margin: [200, 0, 10, 0],
                table: {
                    body: [
                        [{
                            text: (group_total_count === group_key ? '' : 'Please turn over'),
                            bold: true,
                            alignment: 'center',
                            fontSize: 14,
                            style: 'normaltxt'
                        }, ],
                    ]
                },
                pageBreak: (loop_count === result_count ? '' : 'after'),
            });
            content.push(perPageInfo);
        }
        let grandCalculation = [];
        if (sale['total_item_discount_amount'] !== '0.00') {
            grandCalculation = [
                [{
                    text: 'Total Amount',
                    alignmanet: 'right',
                    style: 'h2'
                }, {
                    text: ':',
                    border: [false, false, false, false],
                    style: 'h2',
                    alignment: 'left'
                }, {
                    text: sale['total_item_sale_amount'],
                    fontSize: 8,
                    alignment: 'right'
                }], [{
                    text: 'Discount',
                    alignmanet: 'right',
                    style: 'h2'
                }, {
                    text: ':',
                    border: [false, false, false, false],
                    style: 'h2',
                    alignment: 'left'
                }, {
                    text: sale['total_item_discount_amount'],
                    fontSize: 8,
                    alignment: 'right'
                }], [{
                    text: 'Round Off',
                    alignmanet: 'right',
                    style: 'h2'
                }, {
                    text: ':',
                    border: [false, false, false, false],
                    style: 'h2',
                    alignment: 'left'
                }, {
                    text: sale['roundoff_amount'],
                    fontSize: 8,
                    alignment: 'right'
                }]
            ];
        } else {
            grandCalculation = [
                [{
                    text: 'Round Off',
                    alignmanet: 'right',
                    style: 'h2'
                }, {
                    text: ':',
                    border: [false, false, false, false],
                    style: 'h2',
                    alignment: 'left'
                }, {
                    text: sale['roundoff_amount'],
                    fontSize: 8,
                    alignment: 'right'
                }]
            ];
        }
        let sale_bar_image, sale_bar_img;
        const barCode = document.getElementById(sale['bill_no']);

        if (orgId === this.globalHelper.getConstantsValue('app').ahanaOrgId && sale['bill_no'] && document.contains(barCode)) {
            sale_bar_image = barCode.getElementsByTagName('img')[0].getAttribute('src');
        }

        if (sale_bar_image) { // Check Bar image is empty or not
            sale_bar_img = [{
                image: sale_bar_image,
                height: 20,
                width: 100
            }];
            sale_bar_img[0] = { ...sale_bar_img[0], ...{ alignment: 'right', margin: [0, 0, 0, 0] } };
        } else {
            sale_bar_img = [{
                text: ''
            }];
        }

        // Last leftside signby content ----start ----///
        const signContent: any = [
            [{
                text: 'Billed By',
                style: 'h2'
            }, {
                text: ':',
                style: 'h2'
            }, {
                text: sale['createdby_name'],
                style: 'normaltxt'
            }]
        ];
        if (orgId === ajHospitalOrgId) {
            signContent.push([{
                text: 'Medicine Given By',
                style: 'h2'
            }, {
                text: ':',
                style: 'h2'
            }, {
                text: '',
                style: 'normaltxt'
            }]);
            signContent.push([{
                text: 'Pharmacist Sign',
                style: 'h2',
                margin: [0, 10, 0, 0]
            }, {
                text: '',
                style: 'h2'
            }, {
                text: '',
                style: 'normaltxt'
            }]);
        }

        /// ----end ----//

        content.push({
            border: [false, false, false, false],
            layout: {
                paddingLeft: function (idx, node) {
                    return 0;
                },
                paddingRight: function (idx, node) {
                    return 0;
                },
                paddingTop: function (idx, node) {
                    return 0;
                },
                paddingBottom: function (idx, node) {
                    return 0;
                },
                hLineWidth: function (idx, node) {
                    return 0;
                }
            },
            table: {
                widths: saleReturnData ? ['*', 210, 'auto'] : ['*', 120, '*'],
                body: saleReturnData ? [
                    [{
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            widths: ['*', 'auto', '*'],
                            body: [
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    text: ''
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    text: ''
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        body: [sale_bar_img]
                                    }
                                }]
                            ]
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto', 'auto'],
                            body: [
                                // sale_discount,
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'GST',
                                                alignmanet: 'right',
                                                style: 'h2'
                                            }, {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            }, {
                                                text: (cgst_total + sgst_total).toFixed(2),
                                                fontSize: 8,
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: grandCalculation
                                    }
                                }]
                            ]
                        },
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto'],
                            body: [
                                // sale_discount,
                               [{
                                    border: [false, false, false, false],
                                    colSpan: 1,
                                    layout: 'noBorders',
                                    table: {
                                        widths: [ '*', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'Total',
                                                fillColor: '#eeeeee',
                                                style: 'total',
                                                alignment: 'right',
                                                margin: [7, 0, 7, 0]
                                            }, {
                                                text: ':',
                                                fillColor: '#eeeeee',
                                                style: 'total'
                                            }, {
                                                text: 'INR ' + [sale['bill_amount']],
                                                fillColor: '#eeeeee',
                                                style: 'total',
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }]
                            ]
                        },
                    }],
                ] : [
                    [{
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            body: signContent
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            body: [
                                sale_bar_img
                            ]
                        }
                    }, {
                        border: [false, false, false, false],
                        layout: 'noBorders',
                        table: {
                            alignmanet: 'right',
                            widths: ['auto', 'auto'],
                            body: [
                                // sale_discount,
                                [{
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'GST',
                                                alignmanet: 'right',
                                                style: 'h2'
                                            }, {
                                                text: ':',
                                                border: [false, false, false, false],
                                                style: 'h2'
                                            }, {
                                                text: (cgst_total + sgst_total).toFixed(2),
                                                fontSize: 8,
                                                alignment: 'right'
                                            }]
                                        ]
                                    }
                                }, {
                                    border: [false, false, false, false],
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: grandCalculation
                                    }
                                }], [{
                                    border: [false, false, false, false],
                                    colSpan: 2,
                                    layout: 'noBorders',
                                    table: {
                                        widths: ['auto', 5, 'auto'],
                                        body: [
                                            [{
                                                text: 'Grand Total',
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                                alignment: 'right'
                                                // color: 'white'
                                            }, {
                                                text: ':',
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                                // color: 'white'
                                            }, {
                                                text: 'INR ' + [sale['bill_amount']],
                                                fillColor: '#eeeeee',
                                                style: 'grandtotal',
                                                alignment: 'right'
                                                // color: 'white'
                                            }]
                                        ]
                                    }
                                }]
                            ]
                        },
                    }],
                ]
            }
        });
        return content;
    }

    getSaleReturnPrintContent (printDatas, fromSale) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            saleData = printDatas.saleDatas && fromSale,
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app');
        let return_item = { items: [], bill_no: [], billed_by: '',  payment_type: 0, isDiscount: false };
        if (printDatas.saleDatas) {
            if (printDatas.saleReturnDatas.length) {
                for (let i = 0; i < printDatas.saleReturnDatas.length; i++) {
                    return_item.billed_by = printDatas.saleReturnDatas[i].createdby_name;
                    return_item.payment_type = printDatas.saleReturnDatas[i].salePaymentType;
                    return_item.isDiscount = printDatas.saleReturnDatas.some((discData: any) => discData.total_item_discount_amount !== '0.00');
                    printDatas.saleReturnDatas[i].items.map((return_data: any) => {
                        return_data['bill_no'] = printDatas.saleReturnDatas[i].bill_no;
                        return_item.items.push(return_data);
                    });
                }
            } else {
                return_item = printDatas.saleReturnDatas;
            }
        }
        const saleReturn = printDatas.saleDatas ? return_item : printDatas;
        const content = [];
        const result_count = printDatas.saleDatas ? return_item.items.length : Object.keys(saleReturn.items).length;
        let index = 1;
        let loop_count = 0;
        let cgst_total = 0;
        let sgst_total = 0;
        let total_item_amount = 0;
        let roundoff_amount = 0;
        let total_item_discount_amount: any = 0;
        const groupedArr = this.createGroupedArray(printDatas.saleDatas ? return_item.items : saleReturn.items, 6); // Changed Description rows
        const group_total_count = Object.keys(groupedArr).length;
        for (let i = 0; i < groupedArr.length; i++) {
            const perPageInfo = [];
            const perPageItems = [];
            perPageItems.push([
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'S.No',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Description',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Bill No',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'HSN Code',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Batch',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Expiry',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Qty',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Price',
                    style: 'th'
                },
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Taxable value',
                    style: 'th'
                },
                {
                    border: [false, true, false, true],
                    colSpan: 2,
                    alignmanet: 'center',
                    text: 'CGST',
                    style: 'th'
                }, {},
                {
                    border: [false, true, false, true],
                    colSpan: 2,
                    alignmanet: 'center',
                    text: 'SGST',
                    style: 'th'
                }, {},
                {
                    border: [false, true, false, false],
                    rowspan: 2,
                    text: 'Total',
                    style: 'th'
                },
            ], [
                {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                },
                {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, false, false, true],
                    text: ''
                }, {
                    border: [false, true, false, true],
                    text: 'Rate %',
                    fontSize: 5
                }, {
                    border: [false, true, false, true],
                    text: 'Amount',
                    fontSize: 5
                }, {
                    border: [false, true, false, true],
                    text: 'Rate %',
                    fontSize: 5
                }, {
                    border: [false, false, false, true],
                    text: 'Amount',
                    fontSize: 5
                }, {
                    border: [false, false, false, true],
                    text: ''
                },
            ]);

            for (let j = 0; j < groupedArr[i].length; j++) {
                let particulars;
                const percentage = parseInt(groupedArr[i][j].discount_percentage);
                if (percentage > 0) {
                    particulars = groupedArr[i][j]['prod']['product_fullname'] + '(' + percentage.toString() + ')';
                } else {
                    particulars = groupedArr[i][j]['prod']['product_fullname'];
                }

                cgst_total += parseFloat(groupedArr[i][j].cgst_amount);
                sgst_total += parseFloat(groupedArr[i][j].sgst_amount);
                total_item_amount += parseFloat(groupedArr[i][j].total_amount);
                if (saleData) {
                    roundoff_amount = Math.round(total_item_amount) - total_item_amount;
                    total_item_discount_amount = printDatas.saleReturnDatas.reduce((sum: any, curr: any) => {
                        return sum + parseFloat(curr.total_item_discount_amount);
                    }, 0).toFixed(2);
                }
                let color, border;
                if (loop_count % 2 === 0) {
                    color = '';
                } else {
                    color = '#eeeeee';
                }
                if (result_count === loop_count + 1) {
                    border = [false, false, false, true];
                } else {
                    border = [false, false, false, false];
                }
                perPageItems.push([{
                    border: border,
                    text: index,
                    fillColor: color,
                    style: 'td',
                    alignment: 'left'
                }, {
                    border: border,
                    text: particulars,
                    fillColor: color,
                    style: ['td']
                }, {
                    border: border,
                    text: saleData ? [groupedArr[i][j].bill_no || '-'] : '',
                    fillColor: color,
                    style: 'td'
                }, {
                    border: border,
                    text: [groupedArr[i][j].hsn_no || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: groupedArr[i][j].batch.batch_no,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: groupedArr[i][j].batch.expiry_date,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: groupedArr[i][j].quantity,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: groupedArr[i][j].mrp,
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [groupedArr[i][j].taxable_value || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [groupedArr[i][j].cgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [groupedArr[i][j].cgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [groupedArr[i][j].sgst_percent || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: [groupedArr[i][j].sgst_amount || '-'],
                    fillColor: color,
                    style: 'td'
                },
                {
                    border: border,
                    text: groupedArr[i][j].total_amount,
                    fillColor: color,
                    style: 'td',
                    alignment: 'right'
                },
                ]);
                index++;
                loop_count++;
            }

            if (!saleData) {
                perPageItems.map((items: any) => {
                    items.splice(2, 1);
                });
            }

            let bar_image, bar_img, logo;
            const ahana_log = document.getElementById('sale_logo').getAttribute('src');
            const uhidElement = document.getElementById(saleReturn['uhid']);
            if (saleReturn['uhid'] && uhidElement && uhidElement.getElementsByTagName('img')[0]) {
                bar_image = uhidElement.getElementsByTagName('img')[0].getAttribute('src');
            }

            if (bar_image) { // Check Bar image is empty or not
                bar_img = [{
                    image: bar_image,
                    height: 20,
                    width: 100,
                    alignment: 'right'
                }];
            } else {
                bar_img = [{
                    text: ''
                }];
            }
            // Image Loading End
            if (ahana_log && orgId !== ajHospitalOrgId) {
                logo = [{
                    image: this.imgExport('sale_logo'),
                    height: 20,
                    width: 100
                }];
            } else {
                logo = [{
                    text: ''
                }];
            }
            const header = saleData ? 'Sale Return' : 'Sale Return Bill';
            const dl_nos = 'DL Nos. : MDU/5263/20/21';
            const gst_no = 'GST No : 33AAQFA9991E1Z1';

            perPageInfo.push({
                layout: 'noBorders',
                table: {
                    widths: [saleData ? 100 : 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
                    body: [
                        [{
                            layout: 'noBorders',
                            table: {
                                body: saleData ? [
                                    [{}],
                                    [ {
                                        text: header,
                                        fontSize: 9,
                                        style: 'h2'
                                    }]
                                ] : [
                                    logo,
                                    [{
                                        text: gst_no,
                                        fontSize: 7
                                    }],
                                    [{
                                        text: saleReturn['branch_address'],
                                        fontSize: 9
                                    }],
                                ]
                            },
                        }, {
                            layout: 'noBorders',
                            table: {
                                body: saleData ? [
                                    [{}],
                                    [{
                                        text: '',
                                        fontSize: 9,
                                        style: 'h2'
                                    }]
                                ] : [
                                        [{
                                            text: 'Sale Return Bill',
                                            style: 'h2',
                                            margin: [120, 0, 0, 0]
                                        }]
                                     ],
                            }
                        },
                        {},
                        {},
                        {},
                        {},
                        {
                            layout: 'noBorders',
                            table: {
                                body: saleData ? [
                                    [{}],
                                    [
                                        {
                                            border: [false, false, false, false],
                                            text: ['Payment Type : ' + return_item.payment_type],
                                            style: 'h2'
                                        }
                                    ],
                                ] : [
                                    [{
                                        text: dl_nos,
                                        fontSize: 7,
                                        alignment: 'right'
                                    }],
                                    [{
                                        text: 'Cash on Delivery : ' + [saleReturn['branch_phone']],
                                        fontSize: 9,
                                        alignment: 'right'
                                    }],
                                    bar_img
                                ]
                            },
                        },
                        ],
                    ]
                },
            });
            const { patientTitle, patientName } = this.globalHelper.getPatientTitleAndName(saleReturn['patient_name']);
            perPageInfo.push(saleData ? {} : {
                layout: 'Borders',
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            colSpan: 6,
                            layout: {
                                paddingLeft: function (idx, node) {
                                    return 0;
                                },
                                paddingRight: function (idx, node) {
                                    return 2;
                                },
                                paddingTop: function (idx, node) {
                                    return 0;
                                },
                                paddingBottom: function (idx, node) {
                                    return 0;
                                },
                            },
                            table: {
                                body: [
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Patient',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: `${patientTitle}${patientName}`,
                                        style: 'normaltxt'
                                    }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'UHID',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: [saleReturn['uhid'] || '-'],
                                        style: 'normaltxt'
                                    }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Address',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: this.globalHelper.toTitleCase(saleReturn['patient_address'] || '-'),
                                        style: 'normaltxt'
                                    }
                                    ],
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Doctor',
                                        style: 'h2',
                                        margin: [-5, 0, 0, 0]
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: [saleReturn['consultant_name'] || '-'],
                                        style: 'normaltxt'
                                    }
                                    ],
                                ]
                            },
                        },
                        {}, {}, {}, {}, {},
                        {
                            border: [false, true, false, false],
                            layout: 'noBorders',
                            table: {
                                body: [
                                    [{
                                        border: [false, false, false, false],
                                        text: 'Bill No',
                                        style: 'h2',
                                        margin: [-7, 0, 0, 0],
                                    },
                                    {
                                        text: ':',
                                        border: [false, false, false, false],
                                        style: 'h2'
                                    },
                                    {
                                        border: [false, false, false, false],
                                        text: [saleReturn['bill_no'] + '/' + [saleReturn['salePaymentType']]],
                                        style: 'normaltxt'
                                    }
                                    ],
                                    [{
                                        text: 'Date',
                                        style: 'h2',
                                        margin: [-7, 0, 0, 0],
                                    },
                                    {
                                        text: ':',
                                        style: 'h2'
                                    },
                                    {
                                        text: this.globalHelper.getMomentDatas('app-date-time', saleReturn['created_at']),
                                        style: 'normaltxt'
                                    }
                                    ],
                                ]
                            },
                        }
                        ],
                    ]
                },
            },
                {
                    layout: {
                        hLineWidth: function (idx, node) {
                            return (idx === 0) ? 3 : 1;
                        }
                    },
                    table: {
                        headerRows: 1,
                        widths: saleData ? ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] ,
                        body: perPageItems,
                    }
                });
            perPageInfo.push(saleData ? {} : {
                layout: 'noBorders',
                margin: saleData ? [200, 0, 10, 0] : [200, 0, 10, -6],
                table: {
                    body: [
                        [{
                            text: (group_total_count === (i + 1) ? '' : 'To Be Continue'),
                            bold: true,
                            alignment: 'center',
                            fontSize: 14,
                            style: 'normaltxt'
                        }, ],
                    ]
                },
                pageBreak: (loop_count === result_count ? '' : 'after'),
            });
            content.push(perPageInfo);
        }
        let grandDiscnt = [{}, {}, {}];
        if ((total_item_discount_amount !== '0.00' ) || (saleReturn['total_item_discount_amount'] && saleReturn['total_item_discount_amount'] !== '0.00')) {
            grandDiscnt = [{
                text: 'Discount',
                style: 'h2',
                alignment: 'right'
            },
            {
                text: ':',
                style: 'h2'
            },
            {
                text: saleData ? total_item_discount_amount : saleReturn['total_item_discount_amount'],
                alignment: 'right'
            }];
        }

        const perPageInfoContent = [];
        perPageInfoContent.push({
            layout: 'noBorders',
            table: {
                widths: ['*', 'auto', 'auto', '*', 'auto', '*', 'auto'],
                body: [
                    [
                        {
                        colSpan: 3,
                        layout: 'noBorders',
                        table: {
                            body: [
                                [{
                                    text: 'Billed By',
                                    style: 'h2'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: saleData ? return_item.billed_by : saleReturn['createdby_name'],
                                    style: 'normaltxt'
                                }]
                            ]
                        }
                    }, {}, {},
                    {
                        colSpan: 3,
                        layout: 'noBorders',
                        table: {
                            body: saleData ? [
                                [
                                    {
                                        colSpan: 3,
                                        text: `GST: ${(cgst_total + sgst_total).toFixed(2)}`,
                                        style: 'h2'
                                    },
                                    {},
                                    {}],
                            ] : [
                                [{
                                    text: 'CGST',
                                    style: 'h2'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: cgst_total.toFixed(2),
                                    style: 'normaltxt'
                                }],
                                [{
                                    text: 'SGST',
                                    style: 'h2'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: sgst_total.toFixed(2),
                                    style: 'normaltxt'
                                }]
                            ]
                        },
                    },
                    {},
                    {},
                    {
                        layout: 'noBorders',
                        table: {
                            body: saleData ? [
                                [{
                                    text: 'Total' ,
                                    fillColor: '#eeeeee',
                                    style: 'total',
                                    alignment: 'right'
                                }, {
                                    text: ':' ,
                                    fillColor: '#eeeeee',
                                    style: 'total',
                                    alignment: 'right'
                                }, {
                                    text: total_item_amount.toFixed(2) ,
                                    fillColor: '#eeeeee',
                                    style: 'total',
                                    alignment: 'right'
                                }],
                                [{}, {}, {}],
                                [{
                                    text: 'Total Sale Value',
                                    style: 'h2',
                                    alignment: 'right',
                                    margin: [0, 1, 0, 0]
                                },
                                {
                                    text: ':',
                                    style: 'h2',
                                    margin: [0, 1, 0, 0]
                                },
                                {
                                    text: printDatas.saleDatas['bill_amount'],
                                    alignment: 'right'
                                }],
                                [{
                                    text: 'Sale Return Value',
                                    style: 'h2',
                                    alignment: 'right',
                                    margin: [0, 1, 0, 0]
                                },
                                {
                                    text: ':',
                                    style: 'h2',
                                    margin: [0, 1, 0, 0]
                                },
                                {
                                    text: total_item_amount.toFixed(2),
                                    alignment: 'right',
                                    margin: [0, 1, 0, 0]
                                }],
                                grandDiscnt,
                                [{
                                    text: 'Round Off',
                                    style: 'h2',
                                    alignment: 'right'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: roundoff_amount.toFixed(2),
                                    alignment: 'right'
                                }],
                                [{
                                    text: 'Net Total',
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                },
                                {
                                    text: ':',
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                },
                                {
                                    text: 'INR ' + [(printDatas.saleDatas['bill_amount'] - Math.round(total_item_amount)).toFixed(2)],
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                }]
                            ] : [
                                [{
                                    text: 'GST',
                                    style: 'h2',
                                    alignment: 'right'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: (cgst_total + sgst_total).toFixed(2),
                                    alignment: 'right'
                                }],
                                [{
                                    text: 'Total Value',
                                    style: 'h2',
                                    alignment: 'right'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: saleReturn['total_item_amount'],
                                    alignment: 'right'
                                }],
                                grandDiscnt,
                                [{
                                    text: 'Round Off',
                                    style: 'h2',
                                    alignment: 'right'
                                },
                                {
                                    text: ':',
                                    style: 'h2'
                                },
                                {
                                    text: saleReturn['roundoff_amount'],
                                    alignment: 'right'
                                }],
                                [{
                                    text: 'Grand Total',
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                },
                                {
                                    text: ':',
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                },
                                {
                                    text: 'INR ' + [saleReturn['bill_amount']],
                                    fillColor: '#eeeeee',
                                    style: 'grandtotal',
                                    // color: 'white'
                                }],
                            ]
                        },
                    }
                    ],
                ]
            },
        });
        content.push(perPageInfoContent);
        return content;
    }

    imgExport (imgID) {
        const img: any = document.getElementById(imgID);
        const canvas = document.createElement('canvas');
        canvas.width = img['width'];
        canvas.height = img['height'];

        // Copy the image contents to the canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // Get the data-URL formatted image
        // Firefox supports PNG and JPEG. You could check img.src to
        // guess the original format, but be aware the using "image/jpg"
        // will re-encode the image.
        const dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }

    createGroupedArray (arr, chunkSize) {
        const groups = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            groups.push(arr.slice(i, i + chunkSize));
        }
        return groups;
    }
}
