export const DURATION = [{
    name: '1 Day Ago',
    value: '-1 days'
 }, {
    name: '1 Week Ago',
    value: '-7 days'
 }, {
    name: '1 Month Ago',
    value: '-30 days'
 }, {
    name: '6 Months Ago',
    value: '-180 days'
 }, {
    name: '1 Year Ago',
    value: '-365 days'
 }];
