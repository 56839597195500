import { Injectable } from '@angular/core';

import { LabService } from '../../../services/lab.service';

@Injectable()
export class PatientRequestItemService extends LabService {

    public modelPath = 'lab/patient_request_item';

    checkReqItemNormality (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/check_normality_value`, optionParams);
    }

    deleteAttachment (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/remove_file`, bodyDatas);
    }

    updatePatReqItem (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/update_patient_request_item`, bodyDatas);
    }

}
