import { Component, OnInit, Input, EventEmitter, Output, ViewContainerRef } from '@angular/core';

import { concat, of, Subject } from 'rxjs';
import {  map, tap,  distinctUntilChanged, switchMap, catchError, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit {

    @Input('modelValue') modelValue: any;
    @Input() keyData: string;
    @Output() multiSelectData = new EventEmitter<string>();
    @Output() removeData = new EventEmitter<string>();
    @Output() addtData = new EventEmitter<string>();
    selectOptions: any = {};
    modal: any = {};
    loading = false;

    constructor (private viewContainerRef: ViewContainerRef) {}

    ngOnInit () {
        this.mergeOption();
        if (this.selectOptions.multiple && this.selectOptions.groupByOption) {
            this.selectOptions.datas.map(res => { res['groupBy'] = 'Select All'; });
        }
        if (this.selectOptions.selectedData !== '' && this.selectOptions.selectedData !== undefined &&
            this.selectOptions.selectedData !== null) {
            if (Array.isArray(this.selectOptions.selectedData)) {
                this.modal.data = [...Array.from(new Set(this.selectOptions.selectedData))];
            } else {
                this.modal.data = this.selectOptions.selectedData;
            }
        }
        if (this.selectOptions.typeahead) {
            this.getTypeaheadDatas();
        }
    }

    mergeOption () {
        const defualtOptions = {
            clearable: true,
            multiple: true,
            searchable: true,
            disabled: false,
            minPills: true,
            groupByOption: true,
            selectedData: '',
            closeOnSelect: true,
            pillsTempName: '',
            templateList: false,
            phValue: 'Select',
            selectedLmt: 'none',
            typeahead: false,
            apptTimeTemplate: false
        };
        this.selectOptions = {...defualtOptions, ...this.modelValue};
    }

    getTypeaheadDatas () {
        this.selectOptions.typeaheadCB = new Subject<string>();
        const self = this;
        this.selectOptions.datas = concat(of([]), this.selectOptions.typeaheadCB.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((term: any) => {
            self.loading = true;
        }),
        switchMap((term: any) => {
            if (term && term.trim() !== '') {
            return self.getParentComponent()['searchCB'](term).pipe(
                map((results: any) => {
                if (results) {
                    return results;
                } else {
                    return [];
                }
            }), catchError(() => {
                return of([]);
            }), tap(() => { self.loading = false; }));
            } else {
                self.loading = false;
                return of([]);
            }
        })
        ));
    }

    addChangeConfig (ev, val) {
        const data: any = {
            data: ev,
            value: val
        };
        this.addtData.next(data);
    }

    removeDataModel(ev) {
        const data = (this.selectOptions.multiple && !this.selectOptions.groupByOption &&
            !this.selectOptions.clearable) ? { data: ev.value } : this.modal.data;
        this.removeData.next(data);
    }

    selectItem () {
        const self = this;
        setTimeout(() => {
            // ! -- modal.data is an array so don't use === here
            // tslint:disable-next-line:triple-equals
            if (self.modal.data == 'Select All') {
                self.modal.data = self.selectOptions.datas.map(res => res[self.keyData]);
            }
            self.multiSelectData.next(self.modal.data);
        });
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }
}
