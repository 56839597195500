import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnvService } from '../env/env.service';

@Injectable({ providedIn: 'root' })
export class HttpService {

    baseUrl = `${this.env.baseUrl}${this.env.apiVersion}/`;

    constructor(private http: HttpClient, private env: EnvService) {}

    getMethod(reqPath, options?: object) {
        return this.http.get(`${this.baseUrl}${reqPath}`, options);
    }

    postMethod(reqPath, body ?, options ? ) {
        return this.http.post(`${this.baseUrl}${reqPath}`, body, options);
    }

    putMethod(reqPath, body ?, options ?) {
        return this.http.put(`${this.baseUrl}${reqPath}`, body, options);
    }

    deleteMethod(reqPath, options ?) {
        return this.http.delete(`${this.baseUrl}${reqPath}`, options);
    }
}
