import { Pipe, PipeTransform } from '@angular/core';

import { GlobalHelper } from 'src/app/helper/global.helper';

declare var require: any;
const numberToWords = require('num-words');

@Pipe({ name: 'numberToWord' })
export class NumberToWordPipe implements PipeTransform {

    constructor(private globalHelper: GlobalHelper) {}

    transform(value: any, args?: any): any {
        return this.globalHelper.toTitleCase(numberToWords(parseInt(value)));
    }

}
