import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAvoidSpace]'
})
export class AvoidSpaceDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input') onChange(event) {
    const ele = this.el.nativeElement as HTMLInputElement;
    // Removed typed spaces in the input value
    if (typeof ele.value === 'string') {
      ele.value = ele.value.replace(/ /g, '');
    }
  }

}
