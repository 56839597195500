import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitStringWithSpace'
})
export class SplitStringWithSpacePipe implements PipeTransform {

  transform(value: string, spaceAfter: number = 1): string {
    if (!value || !spaceAfter || spaceAfter <= 0) {
      return value;
    }

    const spacedStringArray = [];
    for (let i = 0; i < value.length; i += spaceAfter) {
      spacedStringArray.push(value.substr(i, spaceAfter));
    }

    return spacedStringArray.join(' ');
  }

}
