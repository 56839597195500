import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[avoid-number]'
})
export class AvoidNumberDirective {

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event,
    reGX = /^([^0-9]*)$/;

    // Not allowed numbers
    if (!reGX.test(e.key)) {
      e.preventDefault();
      return false;
    }
  }
}
