import { Injectable } from '@angular/core';

import { PatientService } from '../../services/patient.service';

@Injectable()
export class ShareModuleService extends PatientService {

    public modelPath = 'configuration/organizations';

    getAllShareModules () {
        return this.httpService.getMethod('configuration/organization_settings/lists');
    }

    getPaymentSetting () {
        return this.httpService.getMethod(`configuration/organization_setting/get_payment_type_mode`);
    }

    getOrgSettings (type: any) {
        return this.httpService.getMethod(`configuration/organization_setting/${type}`);
    }

    getSaleIpBillOption () {
        return this.httpService.getMethod(`configuration/organization_setting/get_sale_ip_bill_options`);
    }

    getPatientShareResource (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_patient_share_resources`, optionParams);
    }

    updatePatientShare (bodyData) {
        return this.httpService.putMethod(`${this.modelPath}/update_patient_sharing`, bodyData);
    }
}
