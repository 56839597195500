import { Injectable } from '@angular/core';

import { EnvService } from '../env/env.service';

declare const Pusher: any;

@Injectable()

export class PusherService {

  pusher;
  appointmentChannel;
  connected = false;

  constructor(private env: EnvService) {
    this.pusher = new Pusher(env.wsKey, {
      cluster: env.wsCluster,
      encrypted: true,
      wsHost: env.wsHost,
      wsPort: env.wsPort,
      wssPort: env.wssPort,
      httHost: env.httpHost,
      forceTLS: env.forceTLS
      // authEndPoint: 'https://ws.medizura.xyz/broadcasting/auth'
    });
    const self = this;
    this.pusher.connection.bind('connected', self.connected = true);
    this.appointmentChannel = this.pusher.subscribe('appointment');
  }
}
