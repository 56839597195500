import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { HttpService } from '../http/http.service';
import { GlobalHelper } from '../../helper/global.helper';

import base64Img from '../../../assets/js/base64-images/base64-img.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class BaseService {

    public modelPath;
    timeout;

    constructor(private router: Router, protected httpService: HttpService, public globalHelper: GlobalHelper,
        public hotkeysService: HotkeysService) { }

    getDTColumns(): any { } // For Data Table

    getPMContent(reportList, branchName?, generatedBy?, totalValues?): any { } // For PDF Maker

    getBarCodeContent(reportDatas): any { } // For PDF Maker

    getAJHeader(docDefinition: any) {
        const styles = {
            title: {
                fontSize: 13,
                bold: true,
                color: '#2f308f',
                margin: [0, 0, 0, 5],
                alignment: 'center'
            },
            unit: {
                alignment: 'center',
                margin: [0, 0, 0, 5],
                color: '#09080d',
                bold: true,
            },
            addr: {
                alignment: 'center'
            },
            logo: {

            },
            lab: {
                corner: 5
            },
            gmail: {
                color: '#d70a8b',
                margin: [0, 20, 0, 0],
            },
            labNo: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 15, 0],
                color: '#d70a8b',
            }
        };

        docDefinition['content'].unshift(
            {
                layout: 'noBorders',
                style: 'tableExample',
                table: {
                    widths: ['15%', '70%', '15%'],
                    body: [
                        [
                            {
                                image: base64Img.ajLogo,
                                width: 80,
                                style: 'logo'
                            },
                            [
                                { text: 'GRAHAM CLINICAL LABORATORY & DIGITAL X-RAY\'S', style: 'title' },
                                { text: '(A Unit of GRAHAM CLINIC)', style: 'unit' },
                                { text: '134, North car street, SIVAKASI - 626 123.', style: 'addr' },
                            ],
                            {
                                image: base64Img.ajLab,
                                width: 65,
                                style: 'lab',
                            },
                        ],
                    ]
                },
            },
            {
                layout: 'noBorders',
                style: 'tableExample',
                table: {
                    widths: ['50%', '50%'],
                    body: [
                        [
                            {
                                text: 'Gmail : grahamlab@yahoo.com', style: 'gmail'
                            },
                            {
                                text: 'Lab No.: 222226 \n\n Hos No: 222366', style: 'labNo',
                            },
                        ],
                    ]
                },
            },
            {
                stack: [
                    {
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: 555, y2: 5, lineWidth: 2, lineColor: '#2f308f' }],
                    },
                ],
            },
        );
        docDefinition['styles'] = { ...docDefinition['styles'], ...styles };
    }

    async printReport(reportList, docDefinition, branchName?, generatedBy?, totalValues?) {
        const curOrgId = this.globalHelper.getCurData('curOrgId'), ajHospitalOrgId = this.globalHelper.getConstantsValue('app').ajHospitalOrgId;
        if (docDefinition.iconFonts) {
            const curURL = window.location.origin;
            pdfMake.fonts = {
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-Italic.ttf'
                },
                TeXGyreBonum: {
                    normal: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Regular.ttf`,
                    bold: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Bold.ttf`,
                    italics: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-Italic.ttf`,
                    bolditalics: `${curURL}/assets/fonts/texgyrebonum/TeXGyreBonum-BoldItalic.ttf`,
                },
                FontAwesome: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fontawesome-webfont.ttf`,
                },
                FaBrand: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-brands-400.ttf`,
                },
                FaRegular: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-regular-400.ttf`,
                },
                Fasolid: {
                    normal: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    bold: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    italics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                    bolditalics: `${curURL}/assets/scss/icons/font-awesome/webfonts/fa-solid-900.ttf`,
                }
            };
        }
        if (branchName && generatedBy && totalValues) {
            docDefinition['content'] = await this.getPMContent(reportList, branchName, generatedBy, totalValues);
        } else if (reportList && reportList.patientBarOnly) {
            docDefinition['content'] = await this.getBarCodeContent(reportList);
        } else {
            docDefinition['content'] = await this.getPMContent(reportList);
        }
        const enabledHeaderFooter = (reportList.patientRequestPrintSetting) ? reportList.patientRequestPrintSetting.show_header_footer : 0;
        if (enabledHeaderFooter && curOrgId === ajHospitalOrgId) {
            await this.getAJHeader(docDefinition);
        }

        const pdf_document = pdfMake.createPdf(docDefinition);
        if (Object.keys(pdf_document).length > 0) {
            pdf_document.print();
        }
        return true;
    }

    getDatatable(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.modelPath}/dt`, bodyDatas || {}, options);
    }

    getServerDTOptions(optionParams?, oWDTParams?) {
        const self = this;
        return {
            ajax: (dtParams: any, callback) => {
                dtParams = { ...dtParams, ...oWDTParams };
                // this usage of jquery angular datatable loading time to disable inner event of table.
                $('.table').addClass('disable-dt-click');
                self.getDatatable(dtParams, optionParams).subscribe((resp: any) => {
                    $('.table').removeClass('disable-dt-click');
                    callback(resp);
                }, error => $('.table').removeClass('disable-dt-click'));
            },
            columns: self.getDTColumns()
        };
    }

    getModelLists(optionParams?: object) {
        return this.httpService.getMethod(`${this.modelPath}/lists`, optionParams);
    }

    createModel(bodyDatas, optionParams?) {
        return this.httpService.postMethod(this.modelPath, bodyDatas, optionParams);
    }

    getModel(modelPk?, optionParams?) {
        const reqPath = `${this.modelPath}${modelPk ? `/${modelPk}` : ``}`;
        return this.httpService.getMethod(reqPath, optionParams);
    }

    updateModel(id, bodyDatas) {
        return this.httpService.putMethod(`${this.modelPath}/${id}`, bodyDatas);
    }

    updateModelColumn(id, bodyDatas) {
        return this.httpService.putMethod(`${this.modelPath}/${id}/updatecolumn`, bodyDatas);
    }

    deleteModel(id, optionParams?) {
        return this.httpService.deleteMethod(`${this.modelPath}/${id}`, optionParams);
    }

    getReportDatatable(bodyDatas?, options?) {
        return this.httpService.postMethod(`${this.modelPath}`, bodyDatas || {}, options);
    }

    getReportDTOptions(optionParams?, oWDTParams?) {
        const self = this;
        return {
            ajax: (dtParams: any, callback) => {
                dtParams = { ...dtParams, ...oWDTParams };
                $('.export_button').hide();
                self.getReportDatatable(dtParams, optionParams).subscribe((resp: any) => {
                    if (resp.data.length) {
                        $('.export_button').show();
                    } else if (document.getElementsByClassName('export_button').item(0)) {
                        document.getElementsByClassName('export_button').item(0).remove();
                    }
                    callback(resp);
                });
            },
            columns: self.getDTColumns()
        };
    }

    getAllActiveTenant() {
        return this.httpService.getMethod('shared/tenants');
    }

    getUserTenants() {
        return this.httpService.getMethod('default/user_tenants');
    }

    switchUserBranch(bodyDatas) {
        return this.httpService.postMethod('default/switch_branch', bodyDatas);
    }

    getServerTime() {
        return this.httpService.getMethod('default/server_time');
    }

    sendWSStatus(optionParams) {
        return this.httpService.getMethod('default/set_websocket_status', optionParams);
    }

    async baseHotKeysSet(pageInfo?) {
        if (this.timeout) { clearTimeout(this.timeout); }
        const self = this;
        this.timeout = setTimeout(async () => {
            self.removehotKey();
            let submitEle, printSubmitEle, cancelEle;
            const element = document.getElementsByClassName('dataTables_wrapper').item(0) ? document.getElementsByClassName('dataTables_wrapper').item(0).getElementsByTagName('input').item(0) : undefined,
                formEle = document.querySelector('form') ? document.querySelector('form').getElementsByTagName('button') : undefined;
            if (formEle) {
                for (let i = 0; i < formEle.length; i++) {
                    const btnTxt = formEle.item(i).textContent.toLowerCase().trim();
                    if (btnTxt === 'save' || btnTxt === 'submit') {
                        submitEle = formEle.item(i);
                    }
                    if (btnTxt.indexOf('print') !== -1) {
                        printSubmitEle = formEle.item(i);
                    }
                    if (btnTxt.indexOf('cancel') !== -1) {
                        cancelEle = formEle.item(i);
                    }
                }
            }
            if (self && pageInfo.buttonDatas && typeof pageInfo.buttonDatas === 'object') {
                if (pageInfo['buttonDatas']['text'].toLowerCase().trim().indexOf('add') === -1) {
                    await self.hotkeysService.add(new Hotkey('f9', (event: KeyboardEvent): boolean => {
                        self.router.navigate([pageInfo.buttonDatas.redUrl]);
                        return false;
                    }, ['INPUT', 'SELECT', 'TEXTAREA'], pageInfo['buttonDatas']['text']));
                    if (!cancelEle) {
                        await self.hotkeysService.add(new Hotkey('f8', (event: KeyboardEvent): boolean => {
                            self.router.navigate([pageInfo.buttonDatas.redUrl]);
                            return false;
                        }, undefined, 'Cancel'));
                    }
                } else {
                    await self.hotkeysService.add(new Hotkey('f5', (event: KeyboardEvent): boolean => {
                        self.router.navigate([pageInfo.buttonDatas.redUrl]);
                        return false;
                    }, ['INPUT', 'SELECT', 'TEXTAREA'], pageInfo['buttonDatas']['text']));
                }
            }
            if (element) {
                await self.hotkeysService.add(new Hotkey('s', (event: KeyboardEvent): boolean => {
                    element.focus();
                    return false;
                }, undefined, 'Search'));
            }
            if (submitEle) {
                await self.hotkeysService.add(new Hotkey('f6', (event: KeyboardEvent): boolean => {
                    submitEle.click();
                    return false;
                }, ['INPUT', 'SELECT', 'TEXTAREA'], 'Save'));
            }
            if (printSubmitEle) {
                await self.hotkeysService.add(new Hotkey('ctrl+p', (event: KeyboardEvent): boolean => {
                    printSubmitEle.click();
                    return false;
                }, ['INPUT', 'SELECT', 'TEXTAREA'], 'Print & Save'));
            }
            if (cancelEle) {
                await self.hotkeysService.add(new Hotkey('f8', (event: KeyboardEvent): boolean => {
                    cancelEle.click();
                    return false;
                }, undefined, 'Cancel'));
            }
        }, 200);
    }

    addHotKey(keyName, keyTitle, elmtRef, workElmtArr) {
        this.hotkeysService.add(new Hotkey(keyName, (event: KeyboardEvent): boolean => {
            elmtRef.click();
            return false;
        }, workElmtArr, keyTitle));
    }

    removehotKey() {
        this.hotkeysService.hotkeys = [this.hotkeysService.hotkeys[0]];
    }

    getExcelExport(tabless, wsnames, wbname, appname) {
        const uri = 'data:application/vnd.ms-excel;base64,'
            , tmplWorkbookXML = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">'
                + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Axel Richter</Author><Created>{created}</Created></DocumentProperties>'
                + '<Styles>'
                + '<Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style>'
                + '<Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style>'
                + '<Style ss:ID="Bold"><Font ss:Bold="1"></Font></Style>'
                + '</Styles>'
                + '{worksheets}</Workbook>'
            , tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}"><Table>{rows}</Table></Worksheet>'
            , tmplCellXML = '<Cell{attributeStyleID}{attributeFormula}><Data ss:Type="{nameType}">{data}</Data></Cell>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)));
            }, format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) {
                    return c[p];
                });
            };
        let ctx = {};
        let workbookXML = '';
        let worksheetsXML = '';
        let rowsXML = '';
        for (let i = 0; i < tabless.length; i++) {
            const particularDiv = document.getElementById(tabless[i]);
            if (particularDiv) {
                const allTables = particularDiv.getElementsByTagName('table').length;
                const tables = [];
                for (let x = 0; x < allTables; x++) {
                    tables[i] = document.getElementById(tabless[i]).getElementsByTagName('table')[x];
                    for (let j = 0; j < tables[i].rows.length; j++) {
                        rowsXML += '<Row>';
                        for (let k = 0; k < tables[i].rows[j].cells.length; k++) {
                            const dataType = tables[i].rows[j].cells[k].getAttribute('data-type');
                            let dataStyle = tables[i].rows[j].cells[k].getAttribute('data-style');
                            let dataValue = tables[i].rows[j].cells[k].getAttribute('data-value');
                            const dataTagvalue = (dataValue) ? dataValue : tables[i].rows[j].cells[k].tagName;
                            dataValue = (dataValue) ? dataValue : tables[i].rows[j].cells[k].innerText;
                            if (dataTagvalue === 'TH') {
                                dataStyle = 'Bold';
                            }
                            if (dataType === 'Number') {
                                dataValue = parseFloat(dataValue.replace(',', ''));
                            }
                            let dataFormula = tables[i].rows[j].cells[k].getAttribute('data-formula');
                            dataFormula = (dataFormula) ? dataFormula : (appname === 'Calc' && dataType === 'DateTime') ? dataValue : null;
                            ctx = {
                                attributeStyleID: (dataStyle === 'Currency' || dataStyle === 'Date' || dataStyle === 'Bold') ? ' ss:StyleID="' + dataStyle + '"' : ''
                                , nameType: (dataType === 'Number' || dataType === 'DateTime' || dataType === 'Boolean' || dataType === 'Error') ? dataType : 'String'
                                , data: (dataFormula) ? '' : dataValue
                                , attributeFormula: (dataFormula) ? ' ss:Formula="' + dataFormula + '"' : ''
                            };
                            rowsXML += format(tmplCellXML, ctx);
                        }
                        rowsXML += '</Row>';
                    }
                }
            }
            ctx = { rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i };
            worksheetsXML += format(tmplWorksheetXML, ctx);
            rowsXML = '';
        }
        ctx = { created: (new Date()).getTime(), worksheets: worksheetsXML };
        workbookXML = format(tmplWorkbookXML, ctx);
        const link = document.createElement('A');
        link['href'] = uri + base64(workbookXML);
        link['download'] = wbname || 'Workbook.xls';
        link['target'] = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
