import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[avoid-repeated-space]'
})
export class AvoidRepeatedSpaceDirective {
  prevKey: number;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const eventObject = <KeyboardEvent> event, inputValue = event.target.value;
    // not allow repeated space
    // Below event.target.selectionStart for in between repeated space avoid
    if ((this.prevKey === 32 && eventObject.keyCode === 32) || (eventObject.keyCode === 32 && (event.target.selectionStart === 0
        || event.target.selectionStart && inputValue[event.target.selectionStart - 1].trim() === ''))) {
      return false;
    }

    this.prevKey = eventObject.keyCode;
  }

}
