import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';

import {Observable} from 'rxjs';
import { GlobalHelper } from '../helper/global.helper';
import * as moment from 'moment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor (private globalHelper: GlobalHelper) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const curPath = this.globalHelper.getPatModCurPath('header');
        const headerOptions: any = {
            'request-time': moment().format('YYYY-MM-DD hh:mm:ss'),
            'config-route': curPath,
            'Access-Control-Allow-Origin': '*'
            // 'x-domain-path': window['origin']
        };

        const patientDetails = this.globalHelper.getCurData('pat-dtl');

        if (patientDetails && typeof patientDetails.patient_global_guid !== 'undefined') {
            headerOptions.patientGlobalGuid = patientDetails.patient_global_guid;
        }

        const currentToken = this.globalHelper.getCurData('curToken');
        if (currentToken) {
            headerOptions.Authorization = `Bearer ${currentToken}`;
        }
        request = request.clone({
            setHeaders: headerOptions
        });

        return next.handle(request);
    }
}
