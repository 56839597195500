import { Injectable } from '@angular/core';

import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class AppSettingService extends ConfigurationService {

    public modelPath = 'configuration/app_configurations';

    updateAppConfig (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/update_by_code`, bodyDatas);
    }

    getPharmacyBranch () {
        return this.httpService.getMethod(`${this.modelPath}/get_pharmacy_branch`);
    }

    getAppConfigByCode (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_configuration_by_code`, optionParams);
    }

    getConfigByGrp (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_configuration_by_group`, optionParams);
    }

    getApplicationStatus () {
        return this.httpService.getMethod(`${this.modelPath}/get_Ws_Es_Cron_status`);
    }

    getTenantBasedPharmacyBranch() {
        return this.httpService.getMethod(`${this.modelPath}/get_tenant_based_pharmacy_branch`);
    }
}
