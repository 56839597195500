import { Injectable } from '@angular/core';

import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class PatientCategoryService extends ConfigurationService {

    public modelPath = 'configuration/patientcategories';

    getDTColumns () {
        const self = this;
        return [{
            name: 'patient_cat_id',
            title: 'Category Id',
            visible: false,
            orderable: false,
            searchable: false,
            data: 'patient_cat_id'
        }, {
            name: 'patient_cat_name',
            title: 'Category Name',
            data: 'patient_cat_name'
        }, {
            name: 'patient_cat_color',
            title: 'Colors',
            class: 'datatable-color-column',
            render: function (data: any, type: any, full: any) {
                return `<span class="patient-round-label" style="background: ${full.patient_cat_color || '#fff'}"></span>`;
            }
        }, {
            title: 'Status',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-patient-category')) {
                    return `<div class="custom-control custom-checkbox">
						<input class="custom-control-input" data-modelPk="checkbox_${full.patient_cat_id}" id="checkbox_${full.patient_cat_id}" type="checkbox" />
						<label class="custom-control-label" for="checkbox_${full.patient_cat_id}"></label>
					</div>`;
                }
                return `<label class="label label-${full.status === 1 ? 'success' : 'danger'}">
					${full.status === 1 ? 'Active' : 'Inactive'}
				</label>`;
            }
        }, {
            title: 'Action',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-patient-category')) {
                    return `<a href="javascript:void(0)" data-modelPk="${full.patient_cat_id}" class="btn btn-secondary">
						<i class="icon-pencil" data-modelPk="${full.patient_cat_id}"></i>
					</a>`;
                }
                return '';
            }
        }];
    }
}
