import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { GlobalHelper } from '../../helper/global.helper';

@Injectable()
export class AccessGuard implements CanActivateChild {

    constructor(private router: Router, private globalHelper: GlobalHelper) {}

    canActivateChild (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const pathArr = state.url.split('/');
        let curPath;
        if (pathArr && pathArr.length > 3) {
            curPath = `${pathArr[1]}/${pathArr[2]}`;
        } else if (pathArr[2] && pathArr[2].indexOf('?') !== -1) {
            curPath = `${pathArr[1]}/${pathArr[2].split('?')[0]}`;
        } else {
            curPath = state.url.slice(1);
        }
        curPath = curPath.substring(0, curPath.indexOf('?'));
        if (this.globalHelper.checkUserAccess(curPath)) { return true; }
        this.router.navigate(['error/403']);
        return false;
    }

}
