
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { SocketData } from 'src/app/constants/index';
import { EnvService } from '../env/env.service';
import * as ReconnectingWebSocket from '../../../assets/js/reconnecting-websocket.min.js';

@Injectable()
export class SocketService {

    private socket = new ReconnectingWebSocket(this.env.wsUrl, null, {debug: true, reconnectInterval: 10000});

    constructor(private env: EnvService) {}

    public send(socketData: SocketData): void {
        // this.socket.emit('message', socketData);
        this.socket.send(JSON.stringify(socketData));
    }

    public onSocketData(): Observable<SocketData> {
        return new Observable<SocketData>(observer => {
            this.socket.onmessage = (msgDatas) => {
                observer.next(JSON.parse(msgDatas.data));
            };
            // this.socket.on('message', (data: SocketData) => observer.next(data));
        });
    }

    public onError(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.onerror = (error) => observer.next(error);
        });
    }

    public onConnect() {
        return new Observable<Event>(observer => {
            this.socket.onopen = () => observer.next();
        });
    }

    public onDisconnect() {
        return new Observable<Event>(observer => {
            this.socket.onclose = (event: any) => observer.next(event.code);
        });
    }

    public disconnect() {
        this.socket.close(1000, 'Work complete');
    }
}
