export const SCHEDULE_TIMINGS = [{
    time: '12.00 am', value: '00:00'
}, {
    time: '12:30 am', value: '00:30'
}, {
    time: '01:00 am', value: '01:00'
}, {
    time: '01:30 am', value: '01:30'
}, {
    time: '02:00 am', value: '02:00'
}, {
    time: '02:30 am', value: '02:30'
}, {
    time: '03:00 am', value: '03:00'
}, {
    time: '03:30 am', value: '03:30'
}, {
    time: '04:00 am', value: '04:00'
}, {
    time: '04:30 am', value: '04:30'
}, {
    time: '05:00 am', value: '05:00'
}, {
    time: '05:30 am', value: '05:30'
}, {
    time: '06:00 am', value: '06:00'
}, {
    time: '06:30 am', value: '06:30'
}, {
    time: '07:00 am', value: '07:00'
}, {
    time: '07:30 am', value: '07:30'
}, {
    time: '08:00 am', value: '08:00'
}, {
    time: '08:30 am', value: '08:30'
}, {
    time: '09:00 am', value: '09:00'
}, {
    time: '09:30 am', value: '09:30'
}, {
    time: '10:00 am', value: '10:00'
}, {
    time: '10:30 am', value: '10:30'
}, {
    time: '11:00 am', value: '11:00'
}, {
    time: '11:30 am', value: '11:30'
}, {
    time: '12:00 pm', value: '12:00'
}, {
    time: '12:30 pm', value: '12:30'
}, {
    time: '01:00 pm', value: '13:00'
}, {
    time: '01:30 pm', value: '13:30'
}, {
    time: '02:00 pm', value: '14:00'
}, {
    time: '02:30 pm', value: '14:30'
}, {
    time: '03:00 pm', value: '15:00'
}, {
    time: '03:30 pm', value: '15:30'
}, {
    time: '04:00 pm', value: '16:00'
}, {
    time: '04:30 pm', value: '16:30'
}, {
    time: '05:00 pm', value: '17:00'
}, {
    time: '05:30 pm', value: '17:30'
}, {
    time: '06:00 pm', value: '18:00'
}, {
    time: '06:30 pm', value: '18:30'
}, {
    time: '07:00 pm', value: '19:00'
}, {
    time: '07:30 pm', value: '19:30'
}, {
    time: '08:00 pm', value: '20:00'
}, {
    time: '08:30 pm', value: '20:30'
}, {
    time: '09:00 pm', value: '21:00'
}, {
    time: '09:30 pm', value: '21:30'
}, {
    time: '10:00 pm', value: '22:00'
}, {
    time: '10:30 pm', value: '22:30'
}, {
    time: '11:00 pm', value: '23:00'
}, {
    time: '11:30 pm', value: '23:30'
}];
