export const DATATABLE_VARIABLES: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10,
    serverSide: true,
    processing: true,
    stateSave: true,
    language: {
        search: '',
        searchPlaceholder: 'Search...',
        processing: '<div class="ngx-datatable material"><div class="datatable-body"><div class="progress-linear" role="progressbar"><div class="container"><div class="bar"></div></div></div></div></div>'
    }
};


// ,
//         paginate: {
//             first: '<i class="datatable-icon-prev"></i>',
//             last: '<i class="datatable-icon-skip"></i>',
//             next: '<i class="datatable-icon-right"></i>',
//             previous: '<i class="datatable-icon-left"></i>'
//         },
