export const LINE_CHART_OPTIONS: any = {
    responsive: true,
    elements: {
        line: {
            tension: 0,
            fill: false
        },
        point: {
            radius: 5
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    if (label && Math.floor(label) === label) {
                        return label;
                    }
                }
            }
        }]
    }
};
