import { RouteInfo } from '../sidebar.metadata';

export const PHARMACYROUTES: RouteInfo[] = [{
    path: '',
    title: 'Sale',
    icon: 'fas fa-medkit',
    class: 'has-arrow',
    extralink: false,
    submenu: [{
        path: '/pharmacy/create-sale',
        title: 'Sale',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/create-sale-return',
        title: 'Sale Return',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sale-returns',
        title: 'Sale Return List',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }]
}, {
    path: '',
    title: 'Purchase',
    icon: 'fas fa-briefcase',
    class: 'has-arrow',
    extralink: false,
    submenu: [{
        path: '/pharmacy/create-purchase',
        title: 'Purchase',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/create-purchase-return',
        title: 'Purchase Return',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/purchase-returns',
        title: 'Purchase Return List',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }]
}, {
    path: '',
    title: 'Reports',
    icon: 'mdi mdi-flag',
    class: 'has-arrow',
    extralink: false,
    submenu: [{
        path: '/pharmacy/purchase-report',
        title: 'Purchase Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/purchase-gst-report',
        title: 'Purchase GST Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sale-report',
        title: 'Sale Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sale-return-report',
        title: 'Sale Return Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sale-gst-report',
        title: 'Sale GST Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/prescription-register',
        title: 'Prescription Register',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/stock-report',
        title: 'Stock Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/patient-outstanding-report',
        title: 'Patient Outstanding Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    },  {
        path: '/pharmacy/short-expiry-report',
        title: 'Short Expiry Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    },  {
        path: '/pharmacy/make-payment-report',
        title: 'Make Payment Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    },  {
        path: '/pharmacy/overall-income-report',
        title: 'Overall Income Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    },  {
        path: '/pharmacy/new-purchase-report',
        title: 'New Purchase Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    },  {
        path: '/pharmacy/non-moving-report',
        title: 'Non Moving Drug',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sold-quantity-report',
        title: 'Sold Quantity Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/patient-wise-sale-report',
        title: 'Patient Wise Sale Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/sale-gst-group-wise',
        title: 'Sale GST Group Wise Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/item-wise-product-report',
        title: 'Item Wise Product Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/fsn-report',
        title: 'FSN Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/stock-transfer-report',
        title: 'Stock Transfer Report',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }]
}, {
    path: '',
    title: 'Stock Details',
    icon: 'mdi mdi-chart-bar',
    class: 'has-arrow',
    extralink: false,
    submenu: [{
        path: '/pharmacy/batch-details',
        title: 'Batch Details',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }, {
        path: '/pharmacy/stock-adjust',
        title: 'Stock Adjust',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
    }]
}, {
    path: '/pharmacy/reorders',
    title: 'Reorders',
    icon: 'mdi mdi-reorder-horizontal',
    class: '',
    extralink: false,
    submenu: []
}, {
    path: '/pharmacy/stock-transfer',
    title: 'Stock Transfer',
    icon: 'mdi mdi-source-branch',
    class: '',
    extralink: false,
    submenu: []
}];
