import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { PatientService } from '../../services/patient.service';
import { PatientDetailFailure, PatientDetailSuccess, StoreActionTypes } from './patients.actions';

@Injectable()
export class StoreEffects {
  constructor(private actions$: Actions, private dataService: PatientService) {}

  @Effect()
  loadPatient$ = this.actions$.pipe(
    ofType(StoreActionTypes.PatientData),
    exhaustMap((action: any) =>
      this.dataService.getPatientByGuid(action.payload).pipe(
        map((patient: any) => {
            return new PatientDetailSuccess(patient); }),
        catchError(() => of(new PatientDetailFailure()))
      )
    )
  );
}

