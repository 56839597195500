import { Routes } from '@angular/router';

import { FullWidthComponent } from './layouts/full-width/full-width.component';
import { FullWidthLeftComponent } from './layouts/full-width-left/full-width-left.component';
import { PatientsComponent } from './layouts/patients/patients.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './services/guards/auth.guard';
import { LoggedInGuard } from './services/guards/logged-in.guard';
import { AccessGuard } from './services/guards/access.guard';

export const Approutes: Routes = [{
    path: '',
    component: FullWidthLeftComponent,
    canActivate: [AuthGuard],
    children: [{
        path: '',
        redirectTo: '/my-works/dashboard',
        pathMatch: 'full'
    }, {
        path: 'configuration',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/configuration/configuration.module#ConfigurationModule'
    }, {
        path: 'pharmacy',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/pharmacy/pharmacy.module#PharmacyModule'
    }, {
        path: 'my-works',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/my-works/my-works.module#MyWorksModule'
    }]
}, {
    path: '',
    component: FullWidthComponent,
    canActivate: [AuthGuard],
    children: [{
        path: '',
        redirectTo: '/out-patients',
        pathMatch: 'full'
    }, {
        path: '',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/lab/lab.module#LabModule'
    }, {
        path: 'out-patients',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/out-patients/out-patients.module#OutPatientsModule'
    }, {
        path: 'in-patients',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/in-patients/in-patients.module#InPatientsModule'
    }, {
        path: 'patient-registration',
        loadChildren: './modules/patient-registration/patient-registration.module#PatientRegistrationModule'
    }]
}, {
    path: '',
    component: PatientsComponent,
    canActivate: [AuthGuard],
    children: [{
        path: '',
        redirectTo: '/patient',
        pathMatch: 'full'
    }, {
        path: 'patient',
        canActivateChild: [AccessGuard],
        loadChildren: './modules/patient/patient.module#PatientModule'
    }]
}, {
    path: '',
    component: BlankComponent,
    children: [{
        path: 'login',
        canActivate: [LoggedInGuard],
        loadChildren: './modules/auth/auth.module#AuthModule'
    }, {
        path: 'error',
        loadChildren: './modules/static-pages/static-pages.module#StaticPagesModule'
    }]
}, {
    path: '**',
    redirectTo: '/error/404'
}];

