import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent {
  @Input('value') value: string;
  @Output() updateColor: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onCPSliderChange (e) {
    this.value = e.color;
    this.updateColor.emit(e.color);
  }

  onColorPickerChange (e) {
    this.value = e;
    this.updateColor.emit(e);
  }

}
