import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { LabBillingService } from 'src/app/modules/lab/request-and-result/services';
import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
  selector: 'app-lab-payment-form',
  templateUrl: './lab-payment-form.component.html',
  styleUrls: ['./lab-payment-form.component.css']
})
export class LabPaymentFormComponent implements OnInit {

  @ViewChild('modalTmpRef') modalTmpRef: NgbModalRef;
  @Input('options') options;
  @Output() modalSaveCB = new EventEmitter<string>();
  formLoading = false;
  payModel: any = {};
  saveAndPrint = false;
  paymodalPrint = false;
  paymodalPrintOptions: any = {};
  accessMaxDate = this.globalHelper.getCurDateObjWithTime('max');
  paymentMode = this.globalHelper.getCurData('payMode');

  constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef,
    private lbService: LabBillingService, private globalHelper: GlobalHelper) { }

  ngOnInit() {
    this.paymodalPrint = false;
    this.saveAndPrint = false;
    this.payModel = this.options.payModel;
    this.payModel.payment_amount = null;
    const option = {
            centered: true,
            windowClass: 'modal-with-date-picker',
            backdropClass: 'modal-with-date-picker'
        }, self = this;
    setTimeout(() => {
      self.options.modalRef = self.modalService.open(self.modalTmpRef, option);
      self.options.modalRef.result.then((result) => {}, (reason) => {
        self.closeModal();
      });
    });
  }

  getParentComponent() {
      return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
  }

  closeModal () {
        this.getParentComponent().payModel = false;
        this.options.modalRef.close();
  }

  printPaymentVoucher (row) {
        row.payment_word = this.globalHelper.convertNumberToWord(parseInt(row.payment_amount));
    this.paymodalPrintOptions = {
      paymentPrint: row,
      voucherPrint: null,
    };
    this.paymodalPrint = true;
    // this.paymentPrint = row;
        // setTimeout(() => {
        // 	let innerContents = document.getElementById("paymentPrint").innerHTML;
        // 	let popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        // 	popupWinindow.document.open();
        // 	popupWinindow.document.write('<html><head><link href="/assets/css/voucher_print.css" rel="stylesheet" type="text/css" /></head><body onload="window.print()">' + innerContents + '</body></html>');
        // 	popupWinindow.document.close();
        // });
  }

  paymentChange(fields) {
    if (fields && fields.length) { fields.map(item => this.payModel[item.name] = ''); }
  }

    onSubmitPayment (formRef) {
        this.payModel.settlement = 'L';
        const self = this;
        // TODO::Need to optimise this condition
        if (!this.paymentMode || (!this.payModel.payment_mode || (_.get(this.paymentMode[this.payModel.payment_mode], 'fields') &&
            this.paymentMode[this.payModel.payment_mode].fields.some(item => !this.payModel[item.name])))) {
            return;
        }
        this.formLoading = true;
        this.lbService.createModel(this.payModel).subscribe((result: any) => {
            formRef.submitted = self.formLoading = false;
            if (self.saveAndPrint) {
                self.printPaymentVoucher(result);
            } else {
                self.options.modalRef.close();
                self.closeModal();
            }
            if (this.options.type === 'in-patient') {
                self.getParentComponent()['encounterOPInit']();
            } else {
                self.getParentComponent()['goInit']();
            }
            self.globalHelper.toastrOpen('S', 'Payment added successful');
        }, error => {
            formRef.submitted = self.formLoading = false;
        });
    }

}
