import * as moment from 'moment';

export const DATE_RANGE_PICKER_OPTIONS = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'left',
    startDate: moment().subtract(1, 'days'),
    endDate: moment().subtract(1, 'days'),
    maxDate: moment().subtract(1, 'days'),
    ranges: {
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        'This Month': [moment().startOf('month'), moment().subtract(1, 'days')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
};
