import { Component, OnInit, HostListener } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { THEME_OPTIONS } from '../../constants/theme-options';
import { GlobalHelper } from '../../helper/global.helper';

@Component({
    selector: 'app-patients',
    templateUrl: './patients.component.html',
    styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    constructor(public globalHelper: GlobalHelper) {}

    public showMobileMenu = false;
    public innerWidth: any;
    public defaultSidebar: any;
    options: any = {};

    ngOnInit() {
        this.options = JSON.parse(JSON.stringify(THEME_OPTIONS));
        this.options['sidebartype'] = 'medium';
        this.defaultSidebar = this.options.sidebartype;
        this.handleSidebar();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.handleSidebar();
    }

    handleSidebar() {
        this.innerWidth = window.innerWidth;
        switch (this.defaultSidebar) {
            case 'full':
            case 'iconbar':
                if (this.innerWidth < 1170) {
                    this.options.sidebartype = 'mini-sidebar';
                } else {
                    this.options.sidebartype = this.defaultSidebar;
                }
                break;
            case 'overlay':
                if (this.innerWidth < 767) {
                    this.options.sidebartype = 'mini-sidebar';
                } else {
                    this.options.sidebartype = this.defaultSidebar;
                }
                break;
            default:
        }
    }

}
