import { Component, OnInit, Input } from '@angular/core';
import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
  selector: 'app-pres-print-template',
  templateUrl: './pres-print-template.component.html',
  styleUrls: ['./pres-print-template.component.css']
})
export class PresPrintTemplateComponent implements OnInit {

    @Input() modelValue: any;
  presPrintData;
  appVars = this.globalHelper.getConstantsValue('app');

  constructor(private globalHelper: GlobalHelper) {}

  ngOnInit() {
    this.presPrintData = this.modelValue.presPrintData;
  }

}
