import { Injectable } from '@angular/core';

import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class MasterCityService extends ConfigurationService {

    public modelPath = 'configuration/cities';

    getDTColumns () {
        const self = this;
        return [{
            name: 'city_id',
            title: 'City Id',
            visible: false,
            orderable: false,
            searchable: false,
            data: 'city_id'
        }, {
            name: 'city_name',
            title: 'City Name',
            data: 'city_name'
        }, {
            title: 'Status',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-city')) {
                    return `<div class="custom-control custom-checkbox">
						<input class="custom-control-input" data-modelPk="checkbox_${full.city_id}" id="checkbox_${full.city_id}" type="checkbox" />
						<label class="custom-control-label" for="checkbox_${full.city_id}"></label>
						</div>`;
                }
                return `<label class="label label-${full.status === 1 ? 'success' : 'danger'}">
					${full.status === 1 ? 'Active' : 'Inactive'}
					</label>`;
            }
        }, {
            title: 'Action',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-city')) {
                    return `<a href="javascript:void(0)" data-modelPk="${full.city_id}" class="btn btn-secondary">
						<i data-modelPk="${full.city_id}" class="icon-pencil"></i>
						</a>`;
                }
                return '';
            }
        }];
    }
}
