import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { WebcamModule } from 'ngx-webcam';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OWL_DATE_TIME_FORMATS,
    DateTimeAdapter,
    OWL_DATE_TIME_LOCALE
} from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { NgxBarcodeModule } from 'ngx-barcode';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ColorPickerModule } from 'ngx-color-picker';

import { MY_MOMENT_DATE_TIME_FORMATS } from '../constants/date-time-picker-format';

import { ComponentsRoutingModule } from './components-routing.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ButtonComponent } from './button/button.component';
import { InnerHeaderComponent } from './inner-header/inner-header.component';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';
import { MultiSelectComponent } from './multi-select/multi-select/multi-select.component';
import { PresPrintTemplateComponent } from './pres-print-template/pres-print-template.component';
import { CommonSaleFormElementsComponent } from './common-sale-form-elements/common-sale-form-elements.component';
import { CommonSaleReturnFormElementsComponent } from './common-sale-return-form-elements/common-sale-return-form-elements.component';
import { SkeletonLoadingComponent } from './skeleton-loading/skeleton-loading.component';

import { BasicModalComponent } from './modals/basic-modal/basic-modal.component';
import { UploadImageComponent } from './modals/upload-image/upload-image.component';
import { RescheduleAppoinmentComponent } from './modals/reschedule-appoinment/reschedule-appoinment.component';
import { UpsertMasterCscComponent } from './modals/upsert-master-csc/upsert-master-csc.component';
import { ProgressComponent } from './modals/progress/progress/progress.component';
import { AddProductComponent } from './modals/add-product/add-product.component';
import { LabPaymentFormComponent } from './modals/lab-payment-form/lab-payment-form.component';
import { LabPaymentPrintComponent } from './print-template/lab-payment-print/lab-payment-print.component';
import { SiteLoadingComponent } from './site-loading/site-loading.component';
import { ResultPrintTemplateComponent } from './result-print-template/result-print-template.component';
import { SaleReturnBillsFormElementsComponent } from './sale-return-bills-form-elements/sale-return-bills-form-elements.component';
import { SaleReturnItemsFormElementsComponent } from './sale-return-items-form-elements/sale-return-items-form-elements.component';
import { RequestPrintBtnComponent } from './request-print-btn/request-print-btn.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';

import { UppercaseDirective } from './directives/uppercase/uppercase.directive';
import { TrueFalseValueDirective } from './directives/truefalsevalue/truefalsevalue.directive';
import { MaxNumberValidationDirective } from './directives/max-number-validation/max-number-validation.directive';
import { MinNumberValidationDirective } from './directives/min-number-validation/min-number-validation.directive';
import { CheckAccessDirective } from './directives/check-access/check-access.directive';
import { FreqDpdnValidateDirective } from './directives/freq-dpdn-validate/freq-dpdn-validate.directive';
import { ScanViewerDirective } from './directives/scan-viewer/scan-viewer.directive';
import { NumberOnlyDirective } from './directives/number-only/number-only.directive';
import { BodyScrollToggleDirective } from './directives/body-scroll-toggle/body-scroll-toggle.directive';
import { AvoidSymbolsDirective } from './directives/avoid-symbols/avoid-symbols.directive';
import { AvoidRepeatedSpaceDirective } from './directives/avoid-repeated-space/avoid-repeated-space.directive';
import { AvoidSelectedCharDirective } from './directives/avoid-selected-char/avoid-selected-char.directive';
import { AvoidNumberDirective } from './directives/avoid-number/avoid-number.directive';
import { AvoidSpaceDirective } from './directives/avoid-space/avoid-space.directive';
import { DelayedUpdateDirective } from './directives/delayed-update/delayed-update.directive';

import { DisplayNameReplacePipe } from './pipes/display-name-replace/display-name-replace.pipe';
import { GroupbyPipe } from './pipes/groupby/groupby.pipe';
import { OrderByPipe } from './pipes/orderBy/order-by.pipe';
import { NumberToWordPipe } from './pipes/number-to-word/number-to-word.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { FreqValCondPipe } from './pipes/freq-val-cond/freq-val-cond.pipe';
import { FreqSrcDatasPipe } from './pipes/freq-src-datas/freq-src-datas.pipe';
import { PrintStringReplacePipe } from './pipes/print-string-replace/print-string-replace.pipe';
import { SumPipe } from './pipes/sum/sum.pipe';
import { SplitStringWithSpacePipe } from './pipes/split-string-with-space/split-string-with-space.pipe';
import { OverlayLoadingComponent } from './overlay-loading/overlay-loading.component';
import { ExportOptionsComponent } from './modals/export-options/export-options.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsRoutingModule,
        NgbModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        WebcamModule,
        NgxBarcodeModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgSelectModule,
        ImageCropperModule,
        Ng2CompleterModule,
        NgxSkeletonLoaderModule.forRoot(),
        ColorPickerModule
    ],
    declarations: [
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SpinnerComponent,
        CheckboxComponent,
        ButtonComponent,
        InnerHeaderComponent,
        BasicModalComponent,
        UploadImageComponent,
        RescheduleAppoinmentComponent,
        UpsertMasterCscComponent,
        ProgressComponent,
        MonthYearPickerComponent,
        MultiSelectComponent,
        PresPrintTemplateComponent,
        AddProductComponent,
        CommonSaleFormElementsComponent,
        LabPaymentFormComponent,
        LabPaymentPrintComponent,
        SiteLoadingComponent,
        CommonSaleFormElementsComponent,
        CommonSaleReturnFormElementsComponent,
        SkeletonLoadingComponent,
        ResultPrintTemplateComponent,
        SaleReturnBillsFormElementsComponent,
        SaleReturnItemsFormElementsComponent,
        RequestPrintBtnComponent,
        ColorPickerComponent,
        OverlayLoadingComponent,

        UppercaseDirective,
        TrueFalseValueDirective,
        MaxNumberValidationDirective,
        MinNumberValidationDirective,
        CheckAccessDirective,
        FreqDpdnValidateDirective,
        BodyScrollToggleDirective,
        AvoidSymbolsDirective,
        AvoidRepeatedSpaceDirective,
        AvoidSelectedCharDirective,
        NumberOnlyDirective,
        AvoidNumberDirective,
        AvoidSpaceDirective,
        DelayedUpdateDirective,
        ScanViewerDirective,

        DisplayNameReplacePipe,
        GroupbyPipe,
        OrderByPipe,
        NumberToWordPipe,
        SafeHtmlPipe,
        FreqValCondPipe,
        FreqSrcDatasPipe,
        PrintStringReplacePipe,
        SumPipe,
        SplitStringWithSpacePipe,
        ExportOptionsComponent
    ],
    exports: [
        SidebarComponent,
        HeaderComponent,
        InnerHeaderComponent,
        FooterComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SpinnerComponent,
        CheckboxComponent,
        ButtonComponent,
        BasicModalComponent,
        UploadImageComponent,
        RescheduleAppoinmentComponent,
        MultiSelectComponent,
        UpsertMasterCscComponent,
        ProgressComponent,
        PresPrintTemplateComponent,
        MonthYearPickerComponent,
        AddProductComponent,
        CommonSaleFormElementsComponent,
        CommonSaleReturnFormElementsComponent,
        SiteLoadingComponent,
        LabPaymentFormComponent,
        LabPaymentPrintComponent,
        SkeletonLoadingComponent,
        ResultPrintTemplateComponent,
        RequestPrintBtnComponent,
        SaleReturnBillsFormElementsComponent,
        SaleReturnItemsFormElementsComponent,
        ColorPickerComponent,
        OverlayLoadingComponent,

        UppercaseDirective,
        TrueFalseValueDirective,
        MaxNumberValidationDirective,
        MinNumberValidationDirective,
        CheckAccessDirective,
        FreqDpdnValidateDirective,
        AvoidSymbolsDirective,
        AvoidRepeatedSpaceDirective,
        ScanViewerDirective,
        AvoidSelectedCharDirective,
        NumberOnlyDirective,
        AvoidNumberDirective,
        AvoidSpaceDirective,
        DelayedUpdateDirective,

        DisplayNameReplacePipe,
        GroupbyPipe,
        OrderByPipe,
        NumberToWordPipe,
        SafeHtmlPipe,
        FreqValCondPipe,
        FreqSrcDatasPipe,
        SumPipe,
        PrintStringReplacePipe,
        PrintStringReplacePipe,
        SplitStringWithSpacePipe,
        ExportOptionsComponent
    ],
    providers: [{
        provide: DateTimeAdapter,
        useClass: MomentDateTimeAdapter,
        deps: [OWL_DATE_TIME_LOCALE]
    }, {
        provide: OWL_DATE_TIME_FORMATS,
        useValue: MY_MOMENT_DATE_TIME_FORMATS
    }]
})
export class ComponentsModule { }
