import { Directive, ElementRef, Input, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appDelayedUpdate]'
})
export class DelayedUpdateDirective implements OnDestroy, AfterViewInit {
  @Input() delay = 250; // Default delay in milliseconds
  delayTimeout: any;
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    const self = this;
      // Clear any existing timeout
      clearTimeout(this.delayTimeout);

      // Set a new timeout to update the input value after the specified delay
      this.delayTimeout = setTimeout(() => {
        self.el.nativeElement.dispatchEvent(new Event('change'));
      }, this.delay);
  }

  ngOnDestroy() {
    // Clean up the timeout when the directive is destroyed
    clearTimeout(this.delayTimeout);
  }
}
