import { Injectable } from '@angular/core';

import { PatientService } from '../../../services/patient.service';

@Injectable()
export class PatientRequestChartService extends PatientService {

    public modelPath = 'lab/patient_graph_item';

    saveGraphItems (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/update_item_based_consultant`, bodyDatas);
    }

    getGraphItems () {
        return this.httpService.getMethod(`${this.modelPath}/get_item`);
    }

    getConsultantBasedOnItem (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_graph_data`, optionParams);
    }

}
