import { Injectable } from '@angular/core';

import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class ProductService extends ConfigurationService {

    public modelPath = 'pharmacy/products';

    getDTColumns () {
        const self = this;
        return [{
            name: 'product_id',
            title: 'Product Id',
            visible: false,
            orderable: false,
            searchable: false,
            data: 'product_id'
        }, {
            name: 'product_name',
            title: 'Product Name',
            data: 'product_fullname'
        }, {
            name: 'product_code',
            title: 'Short Code',
            data: 'short_code'
        }, {
            name: 'description_name',
            title: 'Product Type',
            data: 'product_description'
        }, {
            name: 'brand_name',
            title: 'Product Brand',
            data: 'product_brand'
        }, {
            name: 'generic_name',
            title: 'Generic Name',
            data: 'product_generic'
        }, {
            title: 'Status',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-product')) {
                    return `<div class="custom-control custom-checkbox">
						<input class="custom-control-input" data-modelPk="checkbox_${full.product_id}" id="checkbox_${full.product_id}" type="checkbox" />
						<label class="custom-control-label" for="checkbox_${full.product_id}"></label>
					</div>`;
                }
                return `<label class="label label-${full.status === 1 ? 'success' : 'danger'}">
					${full.status === 1 ? 'Active' : 'Inactive'}
				</label>`;
            }
        }, {
            title: 'Action',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                if (self.globalHelper.checkUserAccess('configuration/update-product')) {
                    return `<a href="javascript:void(0)" data-modelPk="${full.product_id}" class="btn btn-secondary">
						<i class="icon-pencil" data-modelPk="${full.product_id}"></i>
					</a>`;
                }
                return '';
            }
        }];
    }

    getProductsByGenric (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/product_listby_generics`, optionParams);
    }

    getProductsByGenricES (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/ES_product_listby_generic`, optionParams);
    }

    getProductsByTenant (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_product_by_tenant`, optionParams);
    }

    getAllProductUnitLists (optionParams?) {
        return this.httpService.getMethod('pharmacy/product_units/lists', optionParams);
    }

    addNewProductUnit (optionParams) {
        return this.httpService.postMethod('pharmacy/product_units', optionParams);
    }

    getDrugByGeneric (genericId) {
        return this.httpService.getMethod('pharmacy/drug_classes/getdrugbygenerics?generic_id=' + genericId);
    }

    productESearch (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/product_ES_search`, optionParams);
    }

    productSearch (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_product_by_search`, optionParams);
    }

    getProductByDiffTenant (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_product_by_different_tenant`, optionParams);
    }

    getProductDetail (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_product_details`, optionParams);
    }
}
