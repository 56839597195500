import { AfterViewInit, Component, Input, OnInit, ViewContainerRef } from '@angular/core';

import * as _ from 'lodash';

import { GlobalHelper } from '../../helper/global.helper';
import { CommonSaleFormElementsComponent } from '../common-sale-form-elements/common-sale-form-elements.component';

@Component({
    selector: 'app-sale-return-bills-form-elements',
    templateUrl: './sale-return-bills-form-elements.component.html',
    styleUrls: ['./sale-return-bills-form-elements.component.css']
})
export class SaleReturnBillsFormElementsComponent implements OnInit, AfterViewInit {
    @Input() modelData;
    @Input('pageTitle') pageTitle;
    @Input('formRef') formRef;
    @Input('indentId') indentId;
    saleReturnData: any = {};
    allSaleReturnData: any = {};
    tempFinalAmt: any = 0;
    customFormCond = false;
    zeroQtyStatus = false;
    allProducts = [];
    isChecked = false;
    regex = /^[0-9]+$/;
    isSelected = false;

    constructor(private globalHelper: GlobalHelper, private viewContainerRef: ViewContainerRef) {}

    ngOnInit () {
        this.saleReturnData = this.modelData.current_sale_return_data;
        this.allSaleReturnData = this.modelData.all_sale_return_data;
        this.isSelected = this.saleReturnData.product_items.some(({selected}) => !selected);
    }

    ngAfterViewInit () {
        $('input').on('focus', function (ev) {
            $(this).select();
        });
        setTimeout(() => {
            $('input[type=number]').on('mousewheel', function(e) {
                $(this).blur();
            });
        }, 500);
    }

    checkboxChanges (item) {
        this.isSelected = !this.saleReturnData.product_items.some(({selected}) => selected);

        // If the "Sale return bills" table row was disabled means, We should re-calculate and set zeroQtyStatus value.
        // Then only we can click save buttons in the sale form.
        if (!item.selected) {
            item.qtyExceed = false;
            this.itemChanges(item);
        }
    }

    checkReturnQuantity (item) {
        // If the current page title is update and indent id is empty string then only we need to check no stock details
        if (this.pageTitle === 'Update' && this.indentId === '') {
            const pQty = parseFloat(item.quantity),
                pOldQty = parseFloat(item.old_quantity);
            this.customFormCond = item.noStock = item.qtyExceed = false;
            if (pOldQty < pQty) {
                this.checkQuantity(item);
            } else {
                const curQty = (pOldQty - pQty); // *parseFloat(item.package_unit);
                if (curQty > parseFloat(item.batch.available_qty)) {
                    this.customFormCond = item.noStock = true;
                }
            }
        } else {
            this.checkQuantity(item);
        }
    }

    checkQuantity (item) {
        const total = parseFloat(item.quantity) + parseFloat(item.total_returned_quantity);
        this.customFormCond = item.qtyExceed = total > item.sale_quantity;
    }

    itemChanges (item) {
        this.checkReturnQuantity(item);
        if (item.quantity || `${item.quantity}` === '0') {
            item['taxable_value'] = (((parseFloat(item.mrp) / (100 + parseFloat(item.cgst_percent) + parseFloat(item.sgst_percent)) * 100)) * parseFloat(item.quantity)).toFixed(2);
            if (item.discount_percentage) { item.discount_amount = (((item.discount_percentage / 100) * parseFloat(item.taxable_value))).toFixed(2); }
            if (item.discount_amount) { item.taxable_value = (parseFloat(item.taxable_value) - parseFloat(item.discount_amount)).toFixed(2); }
            item.cgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.cgst_percent)) / 100).toFixed(2));
            item.sgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.sgst_percent)) / 100).toFixed(2));
            item.total_amount = (parseFloat(item.taxable_value) + parseFloat(item.sgst_amount) + parseFloat(item.cgst_amount)).toFixed(2);
            this.getTotalReturnAmt();
        }

        const allCheckedBillItems = this.saleReturnData.product_items
            .filter(({ selected, qtyExceed, noStock, quantity }) =>
                selected && (!quantity || !!quantity) && (noStock || !qtyExceed || !!qtyExceed));

        this.zeroQtyStatus = !(allCheckedBillItems
            .every((prodItem: any) =>
                prodItem.quantity && (prodItem.quantity !== 0 || prodItem.quantity.toString() !== 'null') && prodItem.quantity > 0 &&
                !prodItem.qtyExceed && this.regex.test(prodItem.quantity)
            )
        );

        // Should update sale return item table component variables too.
        const saleReturnItems = _.get(this.getParentComponent(), 'saleReturnItemsFormElemts.allSaleReturnData.return_items', []);

        if (saleReturnItems.length) {
            const saleReturnItem = saleReturnItems.find(({sale_item_id}) => sale_item_id === item.sale_item_id);
            // If the sale return bill table component current item data and sale return item table component item are equal means,
            // We should call sale return item table component itemChanges method too.
            if (typeof saleReturnItem === 'object') {
                this.getParentComponent().saleReturnItemsFormElemts.itemChanges(saleReturnItem);
            }
        }
    }

    // Get parent component CommonSaleFormElementsComponent variable and methods
    getParentComponent(): CommonSaleFormElementsComponent {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
    }

    getTotalReturnAmt () {
        this.modelData.all_sale_return_data.total_item_vat_amount = 0;
        this.modelData.all_sale_return_data.total_item_sale_amount = 0;
        const returnItems = this.modelData.all_sale_return_data.return_items;
        for (let i = 0; i < returnItems.length; i++) {
            this.modelData.all_sale_return_data.total_item_vat_amount = (parseFloat(this.modelData.all_sale_return_data.total_item_vat_amount) + parseFloat(returnItems[i].cgst_amount) + parseFloat(returnItems[i].sgst_amount)).toFixed(2);
            this.modelData.all_sale_return_data.total_item_sale_amount = (parseFloat(this.modelData.all_sale_return_data.total_item_sale_amount) + parseFloat(returnItems[i].total_amount)).toFixed(2);
            this.tempFinalAmt = this.tempFinalAmt + parseFloat(returnItems[i].total_amount);
            if (i === returnItems.length - 1) {
                if (this.modelData.all_sale_return_data.total_item_discount_percent) {
                    this.finalDiscount();
                } else {
                    this.modelData.all_sale_return_data.total_item_amount = this.modelData.all_sale_return_data.total_item_sale_amount;
                    this.modelData.all_sale_return_data.bill_amount = Math.round(parseFloat(this.modelData.all_sale_return_data.total_item_amount)).toFixed(2);
                    this.modelData.all_sale_return_data.roundoff_amount = (parseFloat(this.modelData.all_sale_return_data.bill_amount) - parseFloat(this.modelData.all_sale_return_data.total_item_amount)).toFixed(2);
                }
            }
        }
        if (this.modelData.sale_return_data && this.modelData.sale_return_data.length) {
            for (let i = 0; i < this.modelData.sale_return_data.length; i++) {
                const cgst_amount = this.modelData.sale_return_data[i].product_items.reduce((sum: any, curr: any) => {
                    return sum + parseFloat(curr.cgst_amount);
                }, 0);
                const sgst_amount = this.modelData.sale_return_data[i].product_items.reduce((sum: any, curr: any) => {
                    return sum + parseFloat(curr.sgst_amount);
                }, 0);
                this.modelData.sale_return_data[i].total_item_sale_amount = this.modelData.sale_return_data[i].product_items.reduce((sum: any, curr: any) => {
                    return sum + parseFloat(curr.total_amount);
                }, 0);
                this.modelData.sale_return_data[i].total_item_vat_amount = (parseFloat(cgst_amount) + parseFloat(sgst_amount)).toFixed(2);
                if (this.modelData.sale_return_data[i].total_item_discount_percent) {
                    this.modelData.sale_return_data[i].total_item_discount_amount = ((parseFloat(this.modelData.sale_return_data[i].total_item_sale_amount) * this.modelData.sale_return_data[i].total_item_discount_percent) / 100).toFixed(2);
                    this.modelData.sale_return_data[i].total_item_amount = (parseFloat(this.modelData.sale_return_data[i].total_item_sale_amount) - parseFloat(this.modelData.sale_return_data[i].total_item_discount_amount)).toFixed(2);
                    this.modelData.sale_return_data[i].bill_amount = Math.round(parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                    this.modelData.sale_return_data[i].roundoff_amount = (parseFloat(this.modelData.sale_return_data[i].bill_amount) - parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                } else {
                    this.modelData.sale_return_data[i].total_item_amount = this.modelData.sale_return_data[i].total_item_sale_amount;
                    this.modelData.sale_return_data[i].bill_amount = Math.round(parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                    this.modelData.sale_return_data[i].roundoff_amount = (parseFloat(this.modelData.sale_return_data[i].bill_amount) - parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                }
            }
        }
    }

    finalDiscount () {
        this.modelData.all_sale_return_data.total_item_discount_amount = ((parseFloat(this.modelData.all_sale_return_data.total_item_sale_amount) * this.modelData.all_sale_return_data.total_item_discount_percent) / 100).toFixed(2);
        this.modelData.all_sale_return_data.total_item_amount = (parseFloat(this.modelData.all_sale_return_data.total_item_sale_amount) - parseFloat(this.modelData.all_sale_return_data.total_item_discount_amount)).toFixed(2);
        this.modelData.all_sale_return_data.bill_amount = Math.round(parseFloat(this.modelData.all_sale_return_data.total_item_amount)).toFixed(2);
        this.modelData.all_sale_return_data.roundoff_amount = (parseFloat(this.modelData.all_sale_return_data.bill_amount) - parseFloat(this.modelData.all_sale_return_data.total_item_amount)).toFixed(2);
    }

    addReturnItem () {
        const returnItems = this.allSaleReturnData.return_items,
            allCheckedBillItems = this.saleReturnData.product_items
                .filter(({ selected, qtyExceed, noStock, quantity }) =>
                    selected && (!quantity || !!quantity) && (noStock || !qtyExceed || !!qtyExceed)),
            expiredProducts = allCheckedBillItems.length > 0 ? allCheckedBillItems
                .filter((bill_item: any) => this.globalHelper.compareDate(bill_item.expiry_date)) : [];

        if (expiredProducts.length) {
            const productNameList = expiredProducts
                .map(bill_item => bill_item.prod.full_name ? bill_item.prod.full_name : bill_item.prod.product_full_name).join(', ');

            this.globalHelper.toastrOpen('E', `${productNameList} product is expired, so cannot return this product`);
            return;
        }

        this.zeroQtyStatus = !(allCheckedBillItems
            .every((prodItem: any) =>
                prodItem.quantity && (prodItem.quantity !== 0 || prodItem.quantity !== 'null') &&
                prodItem.quantity > 0 && !prodItem.qtyExceed && this.regex.test(prodItem.quantity)));

        if (this.zeroQtyStatus) {
            return;
        }

        const checkedBillItems = allCheckedBillItems.length > 0 ? allCheckedBillItems
            .filter(({ bill_no, product_id, sale_item_id }) =>
                !(returnItems
                    .some((returnItem) =>
                        returnItem.bill_no === bill_no && returnItem.product_id  === product_id &&
                        returnItem.sale_item_id  === sale_item_id)))
            : [];

        if (checkedBillItems.length > 0) {
            this.isChecked = false;
            const billKey = this.indentId === '' ? 'bill_no' : 'sale_id';
            const currSaleReturnDataIndex = this.modelData.sale_return_data
                .findIndex((saleReturn) => this.modelData.current_sale_return_data[billKey] === saleReturn[billKey]);
            if (currSaleReturnDataIndex > -1) {
                const returnSale = allCheckedBillItems
                    .filter(({sale_item_id}) =>
                        this.modelData.sale_return_data[currSaleReturnDataIndex].product_items
                            .every((product) => product.sale_item_id !== sale_item_id));

                returnSale.map((returnSaleData: any) => {
                    this.modelData.sale_return_data[currSaleReturnDataIndex].product_items.push(returnSaleData);
                });
            } else {
                const saleReturnData = JSON.parse(JSON.stringify(this.modelData.current_sale_return_data));
                saleReturnData.product_items = allCheckedBillItems;
                this.modelData.sale_return_data.push(saleReturnData);
            }
            returnItems.push(...checkedBillItems);
            setTimeout(() => {
                checkedBillItems.map((item) => {
                    this.itemChanges(item);
                });
            });
        } else {
            this.isChecked = true;
        }
    }
}
