import { Component, ViewChild, OnInit, EventEmitter, Output, ViewContainerRef, Input } from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {

    @Input('options') options;
    @Output() modalSaveCB = new EventEmitter<string>();
    @Output() modalRemoveCB = new EventEmitter<string>();
    @ViewChild('uploadPatProPicModal') uploadPatProPicModal: NgbModalRef;
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    modalRef;
    imageChanged;
    croppedImage = '';
    cropimg_hidden = false;
    showCropper = false;
    showwebcam = false;
    patientDatas: any = {};
    modalOptions: any = {
        title: 'Delete Profile Photo?',
        notes: 'Are you sure to delete this profile photo?',
        cancelTxt: 'No',
        saveTxt: 'Yes'
    };
    imageChangedEvent: any = {};
    model: any = {};
    errors: WebcamInitError[] = [];
    trigger: Subject<void> = new Subject<void>();
    webcamImage: WebcamImage = null;
    showModal = false;
    unSuprtedIMG = false;

    constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef,
        private globalHelper: GlobalHelper) {}

    ngOnInit() {
        this.patientDatas.patient_picture = this.options.modalDatas.data;
        const self = this;
        setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true,
                size: 'lg'
            };
            self.options.modalRef = self.modalService.open(self.uploadPatProPicModal, option);
            self.checkavailabledevices();
            self.options.modalRef.result.then((result) => { }, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.closeModal();
                }
            });
        });
    }

    fileChangeEvent(event: any): void {
        if (event.target.files && event.target.files.length > 0) {
            const suprdIMG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
            if (suprdIMG.indexOf(event.target.files[0].type) !== -1) {
                this.unSuprtedIMG = false;
                this.cropimg_hidden = false;
                this.imageChangedEvent = event;
            } else {
                event.target.value = this.model.croppedImage = '';
                this.imageChangedEvent = {};
                this.showCropper = false;
                this.unSuprtedIMG = true;
            }
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.model.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    getParentComponent() {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
    }

    removeProPic () {
        this.modalRemoveCB.next(this.patientDatas);
    }

    saveCallback() {
        this.modalSaveCB.next(this.model.croppedImage);
        this.closeModal();
    }

    closeModal() {
        this.getParentComponent().showIPModal = false;
        this.options.modalRef.close();
    }

      checkavailabledevices (): Promise<boolean> {
        const self = this;
        return new Promise((result, reject) => {
            WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                self.showwebcam = mediaDevices && mediaDevices.length >= 1;
                result(self.showwebcam);
            }).catch(err => {
                reject(err);
            });
        });
    }

    triggerSnapshot(): void {
        this.trigger.next();
    }

    handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    handleImage(webcamImage: WebcamImage) {
        const self = this;
        this.checkavailabledevices().then(data => {
            if (data) {
                self.cropimg_hidden = true;
                self.model.croppedImage = 'data:image/png;base64,' + webcamImage.imageAsBase64;
                self.saveCallback();
            } else {
                self.globalHelper.toastrOpen('E', 'Web camera not available!');
            }
        });
    }

    triggerObservable(): Observable<any> {
        return this.trigger.asObservable();
      }
}
